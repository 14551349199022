export const data = [
   { name: "AMERICAN AIRLINES INC.", code: "AA" },
   { name: "CONTINENTAL AIRLINES, INC.", code: "CO" },
   { name: "DELTA AIR LINES, INC.", code: "DL" },
   { name: "AIR CANADA", code: "AC" },
   { name: "UNITED AIRLINES, INC.", code: "UA" },
   { name: "LUFTHANSA CARGO AG.", code: "LH" },
   { name: "HONG KONG DRAGON AIRLINES LIMITED.", code: "KA" },
   { name: "ALITALIA-COMPAGNIA AEREA ITALIANA S.P.", code: "AZ" },
   { name: "AIR FRANCE", code: "AF" },
   { name: "AIR CALEDONIE INTERNATIONAL", code: "SB" },
   { name: "KLM ROYAL DUTCH AIRLINES", code: "KL" },
   { name: "EGYPTAIR", code: "MS" },
   { name: "PHILIPPINE AIRLINES, INC.", code: "PR" },
   { name: "QANTAS AIRWAYS LTD.", code: "QF" },
   { name: "AIR NEW ZEALAND LTD.", code: "NZ" },
   { name: "IRAN-AIR", code: "IR" },
   { name: "AIR INDIA LTD.", code: "AI" },
   { name: "FINNAIR O/Y", code: "AY" },
   { name: "SCANDINAVIAN AIRLINES SYSTEM(SAS)", code: "SK" },
   { name: "BRITISH AIRWAYS P.L.C.", code: "BA" },
   { name: "GARUDA INDONESIA", code: "GA" },
   { name: "JAPAN AIRLINES CO. LTD", code: "JL" },
   { name: "AEROMEXICO", code: "AM" },
   { name: "CATHAY PACIFIC AIRWAYS LTD.", code: "CX" },
   { name: "CARGOLUX AIRLINES INT’L S.A.", code: "CV" },
   { name: "EMIRATES SKY CARGO", code: "EK" },
   { name: "KOREAN AIR LINES CO.,LTD.", code: "KE" },
   { name: "ALL NIPPON AIRWAYS CO., LTD.", code: "NH" },
   { name: "PAKISTAN INT’L AIRLINES", code: "PK" },
   { name: "THAI AIRWAYS INT’L PUBLIC CO.,LTD.", code: "TG" },
   { name: "MALAYSIA AIRLINES SYSTEM BERHAD", code: "MH" },
   { name: "TURKISH AIRLINES INC.", code: "TK" },
   { name: "AIR TAHITI NUI", code: "TN" },
   { name: "UZBEKISTAN AIRWAYS", code: "HY" },
   { name: "AUSTRIAN AIRLINES AG", code: "OS" },
   { name: "AIR PACIFIC", code: "FJ" },
   { name: "ROYAL NEPAL AIRLINES CORP.", code: "RA" },
   { name: "AHK AIR HONG KONG LTD.", code: "LD" },
   { name: "MIAT-MONGOLIAN AIRLINES", code: "OM" },
   { name: "CHINA AIRLINES LTD", code: "CI" },
   { name: "POLAR AIR CARGO INC.", code: "PO" },
   { name: "UNITED PARCEL", code: "5X" },
   { name: "AEROFLOT RUSSIAN AIRLINES", code: "SU" },
   { name: "DALAVIA FAR EAST AIRWAYS", code: "H8" },
   { name: "SRILANKAN AIRLINES LTD.", code: "UL" },
   { name: "SINGAPORE AIRLINES LTD.", code: "SQ" },
   { name: "AIR NIUGINI PTY LTD.", code: "PX" },
   { name: "JAPAN ASIA AIRWAYS CO., LTD.", code: "EG" },
   { name: "EVA AIRWAYS CORP.", code: "BR" },
   { name: "SWISS INT’L AIR LINES LTD.", code: "LX" },
   { name: "VIETNAM AIRLINES", code: "VN" },
   { name: "AIR NIPPON CO., LTD.", code: "EL" },
   { name: "SHANGHAI AIRLINES CO., LTD.", code: "FM" },
   { name: "CHINA EASTERN AIRLINES", code: "MU" },
   { name: "CHINA SOUTHERN AIRLINES", code: "CZ" },
   { name: "VIRGIN ATLANTIC", code: "VS" },
   { name: "NIPPON CARGO AIRLINES", code: "KZ" },
   { name: "ASIANA AIRLINES INC.", code: "OZ" },
   { name: "AIR CHINA LTD.", code: "CA" }
]
