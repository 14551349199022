import React from 'react'
import { useParams } from 'react-router-dom'
import notFound404 from "@assets/images/404.png"
import notFound404men from "@assets/images/404Men.png"
import shadowCircle from "@assets/images/circleShadow.png"
import { useTranslation } from 'react-i18next'

function Contents(getContentsData) {
    const params = useParams()
    const paramsId = params.id
    const contents = getContentsData.getContentsData
    const filteredContent = contents?.filter(item => item.url_ru == paramsId)
    const { t } = useTranslation()


    return (
        <div className="container mx-auto lg:px-60">
            {/* Render header */}
            {filteredContent?.length > 0 ?
                <>
                    <div
                        className="text-4xl my-3 font-semibold"
                        dangerouslySetInnerHTML={{ __html: filteredContent[0].header_ru }}
                    />

                    <div
                        className=""
                        dangerouslySetInnerHTML={{
                            __html: filteredContent[0].body_ru
                                .replace(/\r\n\r\n/g, '<br/>')

                        }}
                    />
                </> :
                <div className='py-20 flex items-center flex-col'>
                    <div className="flex items-center   justify-center relative">
                        <img className='relative bottom-[-50px] left-5' src={notFound404men} alt="" />
                        <div className='flex items-center flex-col'>
                            <img src={notFound404} alt="" />
                            <p className='font-bold text-2xl mt-[40px]'>{t("pageNotFound")}</p>
                        </div>
                    </div>
                    <img className='mt-4' src={shadowCircle} alt="" />
                </div>
            }
        </div>
    )
}

export default Contents