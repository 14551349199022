import React, { useMemo } from 'react'
import moment from 'moment'

import line from "@assets/icons/Line18.svg"
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

export default function TicketAccardion(props) {
  const { t } = useTranslation()


  return (
    <div>
      {(
        // props.item.segments.map((props.item.segments[props.dr], index) => (
        // props.item.segments_direction[props.direction].map((props.drInx) =>
        // props.drInx === index && (
        <>
          <div className="container-box py-2 md:py-1 pb-2 container-box-2" key={props.inx}
          >
            <div className="!left w-full">
              <div className="flex items-center gap-[25px] lg:gap-[30px]  justify-between w-full">
                <div className='flex flex-col items-center'>
                  <p className=" text-lg lg:!text-[33px] text-[#207A85] font-bold ">{props.item.segments[props.dr].dep.time}</p>
                  <p className="text-[13px] lg:text-[15px] text-[#7A8499] font-normal leading-[15px] ">{props.item.segments[props.dr].dep.city.title}</p>
                  <p className="text-[13px] whitespace-nowrap lg:text-[15px] text-[#7A8499] font-semibold">
                    {moment(props.item.segments[props.dr].dep.date, 'DD.MM.YYYY').format("DD MMMM")}
                  </p>
                </div>
                <div className='flex flex-col items-center justify-center lg:w-full'>
                  {/* <p>{props.item.segments[props.dr].provider.supplier.title}</p> */}
                  <div className='w-full flex items-end'>
                    <div className="from ">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g clip-path="url(#clip0_865_2363)">
                          <path fill-rule="evenodd" clipRule="evenodd" d="M4.29285 15.8155C4.02797 15.919 3.91945 16.2356 4.06513 16.4799L5.81319 19.4108C6.06359 19.8306 6.58081 20.0079 7.0361 19.8299L23.9381 13.223C24.7279 12.9143 25.1179 12.0237 24.8092 11.234C24.4883 10.413 23.5436 10.0302 22.7417 10.3961L17.7432 12.6773L10.773 6.27125C10.4838 6.00546 10.0685 5.9276 9.70266 6.0706C9.08963 6.31023 8.85636 7.05604 9.22358 7.60227L13.6983 14.2584L6.85554 17.3571L4.72413 15.8669C4.59802 15.7787 4.43618 15.7594 4.29285 15.8155ZM25.6776 22.9521H5.14764V24.5313H25.6776V22.9521Z" fill="#2d5daf" />
                        </g>
                        <defs>
                          <clipPath id="clip0_865_2363">
                            <rect width="24" height="24" rx="4" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <img loading="lazy" className='w-[80%]  lg:w-full mx-auto' src={line} alt="" />
                    <div className="to ml-[-10px] ">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g clip-path="url(#clip0_1413_5731)">
                          <path fill-rule="evenodd" clipRule="evenodd" d="M6.84152 9.47881C6.57104 9.39093 6.28638 9.56695 6.24415 9.84821L5.73752 13.223C5.66495 13.7064 5.95251 14.1715 6.41742 14.3225L23.6767 19.9304C24.4832 20.1924 25.3494 19.7511 25.6114 18.9446C25.8838 18.1063 25.396 17.2113 24.5439 16.9858L19.2322 15.5804L17.9041 6.20726C17.849 5.81835 17.5772 5.4948 17.2037 5.37342C16.5777 5.17003 15.9244 5.59884 15.862 6.25407L15.1019 14.2384L7.84571 12.2958L7.14239 9.79207C7.10078 9.64392 6.98787 9.52637 6.84152 9.47881ZM25.6776 22.9521H5.14758V24.5313H25.6776V22.9521Z" fill="#2d5daf" />
                        </g>
                        <defs>
                          <clipPath id="clip0_1413_5731">
                            <rect width="24" height="24" rx="4" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                  <p className="text-[14px] lg:text-[16px] mt-[10px]"> {props.item.segments[props.dr].duration.flight.hour}
                    {t("hour")}
                    {props.item.segments[props.dr].duration.flight.minute}
                    {t("min")}
                  </p>
                </div>
                <div className='flex flex-col items-center'  >
                  <p className="text-lg lg:!text-[33px] text-[#207A85] font-bold">{props.item.segments[props.dr].arr.time}</p>
                  <p className="text-[13px] lg:text-[15px] text-[#7A8499] font-normal leading-[15px]">{props.item.segments[props.dr].arr.city.title}</p>
                  <p className="text-[13px] whitespace-nowrap lg:text-[15px] text-[#7A8499] font-semibold">
                    {moment(props.item.segments[props.dr].arr.date, 'DD.MM.YYYY').format("DD MMMM")}
                  </p>
                </div>
              </div>
              {/* <div className='flex flex-col'>
                  <p className="text-[13px] text-[#AEAEAE] font-normal leading-[15px]">{t("reys")} {props.item.segments[props.dr].fare_code}</p>
                  <p className="text-[13px] text-[#AEAEAE] font-normal">
                          {props.item.segments[props.dr].aircraft.title}
                        </p>
                </div> */}
              {/* <img className="w-10 rounded-full" src={`https://mpics.avs.io/al_square/240/240/${props.item.segments[props.dr].provider.supplier.code}.png`} alt="" /> */}

              {props.item.segments[props.dr].duration.transfer?.minute >= 0 && (
                <div className="flex justify-center p-[20px] flex-col bg-[#F7F7F7]  h-[95px]  rounded-lg mt-4">
                  <h1 className=' text-[15px] lg:text-[20px] font-bold'>
                    {t("peresadka")}
                  </h1>
                  <p className='text-[14px] lg:text-[16px] font-normal mt-[8px]'>
                    {props.item.segments[props.dr].duration.transfer?.hour}ч {props.item.segments[props.dr].duration.transfer?.minute}мин {t("peresadka")} <br />
                    В аэропорту "{props.item.segments[props.dr].arr.airport.title}"
                  </p>
                </div>
              )}
            </div>
          </div>
        </>
        // )
      )
      }
    </div>
  )
}