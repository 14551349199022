import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { LockPlugin, easepick } from '@easepick/bundle';
import { RangePlugin } from '@easepick/range-plugin';
import { useSearchAirportsMutation, useSearchAirportsToMutation, useGetRecommendationMutation, useGetTicketPriceMutation } from "@services/AllApi";
import { setPopularCities, changeTicketData, clearFilterAirlinesName, clearFilterAirlines, addFilterAirlinesName, setPopularCitesCode, setCountTicket, setGetRecSuc, setGetRecLoad, setGetRecommendationSuc, setLoadingGetRecommendation, setGetRecommendationData, setGetRecommendationErr, changeGetSortRequest } from "@Slice/HomeSlice";
import { useTranslation } from 'react-i18next';
import StrongFly from "./StrongFly";
import { useGetMyCityQuery } from "@services/MyCityApi";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PassengerDropdown from "./PassengerDropdown";
import airportAriline from '@assets/icons/airportAriline.svg'
import arrowSwap from "@assets/icons/arrow-swap-horizontal.svg"
import { setDatePickerBack, setMyAirlinesToInpErr, setStrongFly, setStrongFlyArr, setStrongFlyNum, setActiveIndex } from "@Slice/HomeSlice";
import closeCircle from '@assets/icons/close-circle2.svg'
import airplane2 from "@assets/icons/airplane2.svg"
import arrowSwap2Black from "@assets/icons/arrow-3Black.svg"
import Switch from '@mui/material/Switch';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import FromModal from "./FromModal";
import WhereModal from "./WhereModal";
import StrongFlyModal from "./StrongFlyModal";
import Trailways from './Trailways';


export default function Flights(props) {

    const myInputRef = useRef(null);
    const pickerDateRef = useRef(null);
    const datepickerBackRef = useRef(null);
    const navigate = useNavigate()
    const parts = props.parts

    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => { setOpen(false) };
    const handleOpen2 = () => setOpen2(true);
    const handleClose3 = () => setOpen3(false);

    const dispatch = useDispatch()
    const params = useParams()
    const { t } = useTranslation()
    const { pathname, state } = useLocation();

    const lang = useSelector((state) => state.AllSlice.lang);
    const myAirlinesToInpErr = useSelector((state) => state.HomeSlice.myAirlinesToInpErr);
    const popularCitesName = useSelector((state) => state.HomeSlice.popularCitesName);
    const currency = useSelector((state) => state.AllSlice.currency);
    const changeFilterAir = useSelector((state) => state.HomeSlice.changeFilterAir);
    const filterDirect = useSelector((state) => state.HomeSlice.filterDirect);
    const countTicket = useSelector((state) => state.HomeSlice.countTicket);
    const changeFilterDirect = useSelector((state) => state.HomeSlice.changeFilterDirect);
    const ticketData = useSelector((state) => state.HomeSlice.ticketData);
    const priceOrder = useSelector((state) => state.HomeSlice.priceOrder);
    const arrOrder = useSelector((state) => state.HomeSlice.arrOrder);
    const depOrder = useSelector((state) => state.HomeSlice.depOrder);
    const datePickerBack = useSelector((state) => state.HomeSlice.datePickerBack);
    const durationOrder = useSelector((state) => state.HomeSlice.durationOrder);
    const strongFly = useSelector((state) => state.HomeSlice.strongFly);
    const strongFlyNum = useSelector((state) => state.HomeSlice.strongFlyNum);
    const strongFlyArr = useSelector((state) => state.HomeSlice.strongFlyArr);
    const getSortRequest = useSelector((state) => state.HomeSlice.getSortRequest);

    const { version, isTicketPagination, setIsTicketPagination, getPassengerText, isBaggageStrong, setIsBaggageStrong, setLoader } = props
    const filterAirlines = useSelector((state) => state.HomeSlice.filterAirlines);

    const [langForDataPicker, setLangForDataPicker] = useState('ru-RU')

    const [ticketAdults, setTicketAdult] = useState(parts?.length === 9 ? parseInt(parts[4]) : parts?.length === 8 && parseInt(parts[3]) || 1)
    const [ticketChild, setTicketChild] = useState(parts?.length === 9 ? parseInt(parts[5]) : parts?.length === 8 && parseInt(parts[4]) || 0)
    const [ticketBabies, setTicketBabies] = useState(parts?.length === 9 ? parseInt(parts[6]) : parts?.length === 8 && parseInt(parts[6]) || 0)
    const [ticketTarif, setticketTarif] = useState(parts?.length === 9 ? parts[7] : parts?.length === 8 && parts[6] || "a")
    const [myAirlinesDateInpErr, setMyAirlinesDateInpErr] = useState(false)
    const [dateHotelPicker2, setDateHotelPicker2] = useState('');
    const [allPricePicker, setAllPricePicker] = useState([])
    const [myAirlinesTo, setMyAirlinesTo] = useState()
    const [myAirlines, setMyAirlines] = useState()
    const [fromCodeErr, setFromCodeErr] = useState(false)
    const [airlinesDataFrom, setAirlinesDataFrom] = useState()
    const [myAirlinesCode, setMyAirlinesCode] = useState()
    const [toCodeErr, setToCodeErr] = useState(false)
    const [dateCodeErr, setDateCodeErr] = useState(false)
    const [classModalShow, setClassModalShow] = useState(false)
    const [dateHotelPicker1, setDateHotelPicker1] = useState();

    const [airlinesDataTo, setAirlinesDataTo] = useState()
    const [myAirlinesInpErr, setMyAirlinesInpErr] = useState(false)
    const [formData, setFormData] = useState({});
    const [myCityChange, setMyCityChange] = useState(true)
    const [myCityToChange, setMyCityToChange] = useState(true)
    const [myCityChangeLang, setMyCityChangeLang] = useState(false)
    const [myCityChangeLangTo, setMyCityChangeLangTo] = useState(false)
    const [myAirlinesCountry, setMyAirlinesCountry] = useState()
    const [myAirlinesCountryTo, setMyAirlinesCountryTo] = useState()
    const [myAirlinesCodeTo, setMyAirlinesCodeTo] = useState()
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [dates, setDates] = useState([]);
    const [autoGet, setAutoGet] = useState(false);
    const [headerText, setHeaderText] = useState(t('Когда'));

    const [tokenAirlines, setTokenAirlines] = useState()
    const [isBaggage, setIsBaggage] = useState("null")
    const [countGet, setcountGet] = useState(true)

    const [getTicketPrice, { data: ticketPriceData, isSuccess: ticketPriceSuc, isLoading: ticketPriceLoading }] = useGetTicketPriceMutation()
    const [searchAirports, { data: searchAirportsFrom, isSuccess: searchAirportsFromSuc }] = useSearchAirportsMutation()
    const [searchAirportsto, { data: searchAirportsTo, isSuccess: searchAirportsToSuc }] = useSearchAirportsToMutation()
    const [getRecommendation, { data: getRecommendationData, isLoading: loadingGetRecommendation, isSuccess: getRecommendationSuc, isError: getRecommendationErr, error: getRecommendationError }] = useGetRecommendationMutation()
    const { data: getMyCityData, isLoading: getMyCityLoading, isSuccess: getMyCitySuc, isError: getMyCiryErr } = useGetMyCityQuery()

    const totalPassengers = ticketAdults + ticketChild + ticketBabies;


    const childAdtErr = () => toast.error(t("childErr"))

    useEffect(() => {
        parts?.length === 9 ? dispatch(setDatePickerBack(true)) : parts?.length === 8 && dispatch(setDatePickerBack(false))
    }, [parts])

    const handleChangeVal = (name, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    const searchAirportsFnc = (e, inx) => {
        setAirlinesDataFrom()
        if (e.length >= 2) {
            const searchData = {
                lang: lang,
                part: e
            }
            searchAirports(searchData)
        } else {
            !strongFly && setMyAirlinesCode()
            strongFly && handleChangeVal(`fromCode${inx}`, '');
            strongFly && handleChangeVal(`fromCity${inx}`, '');
        }
    }

    useEffect(() => {
        if (searchAirportsFromSuc && searchAirportsFrom) {
            let cities = searchAirportsFrom?.data?.cities;

            if (cities) {
                if (params.id && (parts?.length >= 8) && myCityToChange || myCityChangeLang) {
                    setMyAirlines(Object.values(cities)[0].cityName);
                    // setMyAirlinesCode(Object.values(cities)[0].cityIataCode)
                    setMyAirlinesCountry(Object.values(cities)[0].countryName);
                    setAirlinesDataFrom([]);
                    setMyCityChangeLang(false);
                    setMyCityToChange(false)
                } else {
                    setAirlinesDataFrom(Object.values(cities));
                }
            }
            setLoader(false);
        }
    }, [searchAirportsFrom, searchAirportsFromSuc]);

    const searchAirportsToFnc = (e, inx) => {
        setAirlinesDataTo()
        if (e?.length > 2) {
            const searchData = {
                lang: lang,
                part: e
            }
            searchAirportsto(searchData)
        } else {
            !strongFly && setMyAirlinesCodeTo()
            strongFly && handleChangeVal(`toCode${inx}`, '');
            strongFly && handleChangeVal(`toCity${inx}`, '');
        }
    }

    useEffect(() => {
        if (searchAirportsToSuc && searchAirportsTo && searchAirportsTo.data.cities) {
            let cities = searchAirportsTo.data.cities
            setAirlinesDataTo(Object.values(cities))

            if (params.id && (parts?.length >= 8) && myCityChange || myCityChangeLangTo) {
                // setMyAirlinesCodeTo(Object.values(cities)[0].cityIataCode);
                setMyAirlinesTo(Object.values(cities)[0].cityName)
                setMyAirlinesCountryTo(Object.values(cities)[0].countryName)
                setAirlinesDataTo()
                setMyCityChangeLangTo(false);
                setMyCityChange(false)
            }
        }
    }, [searchAirportsTo, searchAirportsToSuc])


    useEffect(() => {
        const languageCode = lang === 'en' ? 'en-US' : lang === 'ru' ? 'ru-RU' : lang === 'uz' ? 'uz-UZ' : 'en-US';
        setLangForDataPicker(languageCode)
    }, [lang])


    useEffect(() => {

        if (!strongFly && (pathname == '/' || windowWidth > 1024)) {
            pickerDateRef.current = new easepick.create({
                element: document.getElementById('datepicker'),
                css: [
                    'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css',
                    'https://easepick.com/css/demo_hotelcal.css',
                    'https://firebasestorage.googleapis.com/v0/b/customizestyledatepicker.appspot.com/o/customizeDatepicker.css?alt=media&token=b09d1bf8-ff59-4417-84ab-d36f849e9844'
                ],
                calendars: 1,
                zIndex: 51,
                lang: langForDataPicker,
                grid: 1,
                plugins: [LockPlugin],
                LockPlugin: {
                    minDate: new Date(),
                },
                setup(pickerDateRef) {
                    pickerDateRef.on('select', (e) => {
                        const selectedDate = e.detail.date ? e.detail.date.format("DD-MM-YYYY") : null;
                        if (selectedDate) {
                            setDateHotelPicker1(selectedDate);
                            setMyAirlinesDateInpErr(false);
                        }
                    });
                }
            });

            datepickerBackRef.current = new easepick.create({
                element: document.getElementById('datepickerRange'),
                css: [
                    'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css',
                    'https://easepick.com/css/demo_hotelcal.css',
                    'https://firebasestorage.googleapis.com/v0/b/customizestyledatepicker.appspot.com/o/customizeDatepicker.css?alt=media&token=b09d1bf8-ff59-4417-84ab-d36f849e9844',
                    'https://cdn.jsdelivr.net/npm/@easepick/core@1.2.1/dist/index.css',
                    'https://cdn.jsdelivr.net/npm/@easepick/range-plugin@1.2.1/dist/index.css'
                ],
                calendars: 2,
                zIndex: 51,
                lang: langForDataPicker,
                grid: 2,
                plugins: [RangePlugin, LockPlugin],
                header: headerText,

                RangePlugin: {
                    repick: false,
                    tooltip: true,
                },
                LockPlugin: {
                    minDate: new Date(),
                },
                setup(datepickerBackRef) {
                    datepickerBackRef.on('select', (e) => {
                        const startDate = e.detail.start ? e.detail.start.format("DD-MM-YYYY") : null;
                        const endDate = e.detail.end ? e.detail.end.format("DD-MM-YYYY") : null;

                        // Handle start date and end date separately

                        if (startDate) {
                            setDateHotelPicker1(startDate);
                        }
                        if (endDate) {
                            setDateHotelPicker2(endDate);
                        }
                        setMyAirlinesDateInpErr(false);
                    });
                }
            });
        }
    }, [strongFly, langForDataPicker]);


    useEffect(() => {
        if (!strongFly && dateHotelPicker2) {
            setDateHotelPicker1('')
            setDateHotelPicker2('')
            datepickerBackRef.current.clear()
        }
    }, [strongFly])

    function handleFromInputChange(e) {
        const value = e.target.value;
        setMyAirlines(value);
        setMyAirlinesInpErr(false)
        if (!value) {
            setMyAirlinesCode('');
            setMyAirlinesCountry('');
        }
        searchAirportsFnc(value);
    }


    const getRecommendationFnc = (e) => {
        window.screenY = 0;
        // window.scrollTo(0, 0)
        e !== 'add_count' && dispatch(changeTicketData(undefined));

        console.log(dateHotelPicker1, 'dateHotelPicker1');
        console.log(dateHotelPicker2, 'dateHotelPicker2');

        if (ticketAdults >= ticketBabies) {
            if (e === 'for_btn') {
                dispatch(clearFilterAirlinesName());
                dispatch(clearFilterAirlines());
            }
            if (myAirlinesCode) {
                setMyAirlinesInpErr(false);

                if (myAirlinesCodeTo || parts?.length >= 8 && parts[1]) {

                    dispatch(setMyAirlinesToInpErr(false));
                    if (dateHotelPicker1) {
                        setMyAirlinesDateInpErr(false);
                        const recomData = {
                            adt: ticketAdults,
                            chd: ticketChild,
                            inf: ticketBabies,
                            ins: 0,
                            src: 0,
                            yth: 0,
                            lang: lang,
                            segments: dateHotelPicker2 && datePickerBack ? [
                                {
                                    from: myAirlinesCode,
                                    to: myAirlinesCodeTo,
                                    date: dateHotelPicker1
                                },
                                {
                                    from: myAirlinesCodeTo,
                                    to: myAirlinesCode,
                                    date: dateHotelPicker2
                                }
                            ] : [{
                                from: myAirlinesCode,
                                to: myAirlinesCodeTo,
                                date: dateHotelPicker1
                            }],
                            filter_airlines: filterAirlines,
                            is_direct_only: Number(filterDirect),
                            gds_white_list: [],
                            gds_black_list: [],
                            class_: ticketTarif,
                            token: filterAirlines.length >= 1 ? tokenAirlines : '',
                            is_charter: false,
                            count: countTicket
                        };

                        // Conditionally add order properties
                        if (Number(priceOrder) !== 0) {
                            recomData.price_order = Number(priceOrder);
                        }
                        if (Number(arrOrder) !== 0) {
                            recomData.arr_order = Number(arrOrder);
                        }
                        if (Number(depOrder) !== 0) {
                            recomData.dep_order = Number(depOrder);
                        }
                        if (Number(durationOrder) !== 0) {
                            recomData.duration_order = Number(durationOrder);
                        }

                        getRecommendation(recomData);
                        setAutoGet(false)
                    } else {
                        setMyAirlinesDateInpErr(true);
                    }
                } else {
                    dispatch(setMyAirlinesToInpErr(true));
                }
            }
            else {
                setMyAirlinesInpErr(true);
            }
        } else {
            childAdtErr()
        }
    };

    const getRecommendationDifficult = (e) => {
        setFromCodeErr(false)
        setToCodeErr(false)
        setDateCodeErr(false)
        var isError = false
        e !== 'add_count' && dispatch(changeTicketData(undefined))
        if (e === 'for_btn') {
            dispatch(clearFilterAirlinesName())
            dispatch(clearFilterAirlines())
        }
        const allFlyDirection = strongFlyArr && strongFlyArr.map((item, inx) => ({
            from: formData[`fromCode${item}`],
            to: formData[`toCode${item}`],
            date: formData[`date${item}`]
        }))


        for (let index = 0; index < strongFlyArr.length; index++) {
            const item = strongFlyArr[index];
            if (!formData[`fromCode${item}`]) {
                isError = true
                setFromCodeErr(index);
                break;

            }
            if (!formData[`toCode${item}`]) {
                isError = true
                setToCodeErr(index);
                break;
            }
            if (!formData[`date${item}`]) {
                isError = true
                setDateCodeErr(index);
                break;
            }
        }
        if (ticketAdults >= ticketBabies) {
            if (!isError) {
                setMyAirlinesInpErr(false)
                dispatch(setMyAirlinesToInpErr(false))
                setMyAirlinesDateInpErr(false)
                handleClose3()
                const recomData = {
                    adt: ticketAdults,
                    chd: ticketChild,
                    inf: ticketBabies,
                    ins: 0,
                    src: 0,
                    yth: 0,
                    lang: lang,
                    segments: allFlyDirection,
                    filter_airlines: filterAirlines,
                    is_direct_only: Number(filterDirect),
                    gds_white_list: [],
                    gds_black_list: [],
                    class_: ticketTarif,
                    token: filterAirlines.length >= 1 ? tokenAirlines : '',
                    is_charter: false,
                    count: countTicket,
                }
                // Conditionally add order properties
                if (Number(priceOrder) !== 0) {
                    recomData.price_order = Number(priceOrder);
                }
                if (Number(arrOrder) !== 0) {
                    recomData.arr_order = Number(arrOrder);
                }
                if (Number(depOrder) !== 0) {
                    recomData.dep_order = Number(depOrder);
                }
                if (Number(durationOrder) !== 0) {
                    recomData.duration_order = Number(durationOrder);
                }

                getRecommendation(recomData)
                setAutoGet(false)
            }
        }
        else {
            childAdtErr()
        }
    }

    const getRecommendationErrApi = () => {
        try {
            const errorMessage = getRecommendationError?.data[lang];
            if (errorMessage) {
                toast.error(errorMessage);
            }
        } catch (error) {
            console.error("An unexpected error occurred:", error);
            toast.error("An unexpected error occurred.");
        }
    };

    useEffect(() => {
        getRecommendationErr && getRecommendationErrApi()
    }, [getRecommendationErr])


    useEffect(() => {
        if (changeFilterAir) {
            if (changeFilterAir && windowWidth > 1024) {
                if (strongFly) {
                    getRecommendationDifficult()
                } else {
                    getRecommendationFnc()
                }
            }
        }
    }, [changeFilterAir, filterAirlines])


    useEffect(() => {
        if (changeFilterDirect && windowWidth > 1024) {
            if (strongFly) {
                getRecommendationDifficult()
            } else {
                getRecommendationFnc()
            }
        }
    }, [filterDirect])


    useEffect(() => {
        if (windowWidth > 1024) {
            if (arrOrder !== 0 || depOrder !== 0 || durationOrder !== 0) {
                if (strongFly) {
                    getRecommendationDifficult()
                } else {
                    getRecommendationFnc()
                }
            }
        }
    }, [priceOrder, arrOrder, depOrder, durationOrder]);


    useEffect(() => {
        if (getRecommendationSuc && getRecommendationData.success == true) {
            setcountGet(true)
            setIsTicketPagination(true)
            if (ticketData?.flights.length === getRecommendationData?.data.flights.length) {
                setIsTicketPagination(false)
            }
            dispatch(changeTicketData(getRecommendationData.data))

            getRecommendationData.data && setTokenAirlines(getRecommendationData.data.search?.token)
            getRecommendationData.data && dispatch(addFilterAirlinesName(getRecommendationData.filter_airlines_items));
        }
    }, [getRecommendationData])


    const changeCountGetRecom = useCallback(async () => {
        countTicket > 20 && getRecommendationFnc('add_count')
    }, [countTicket])


    useEffect(() => {
        changeCountGetRecom()
    }, [countTicket])


    useEffect(() => {
        if (ticketPriceData && pickerDateRef.current) {

            var prices = {};
            prices = ticketPriceData[String(currency).toLowerCase()]

            pickerDateRef.current.on('view', (evt) => {
                const { view, date, target } = evt.detail;
                const d = date ? date.format('YYYY-MM-DD') : null;

                if (view === 'CalendarDay' && prices[d]) {
                    const span = target.querySelector('.day-price') || document.createElement('span');
                    span.className = 'day-price';
                    span.innerHTML = `${prices[d]}`;
                    target.append(span);
                }
            });

            datepickerBackRef.current.on('view', (evt) => {
                const { view, date, target } = evt.detail;
                const d = date ? date.format('YYYY-MM-DD') : null;

                if (view === 'CalendarDay' && prices[d]) {
                    const span = target.querySelector('.day-price') || document.createElement('span');
                    span.className = 'day-price';
                    span.innerHTML = `${prices[d]}`;
                    target.append(span);
                }
            });

            var isOpenWhere = pickerDateRef.current.isShown()
            var isOpenBack = datepickerBackRef.current.isShown()

            pickerDateRef?.current?.hide()
            isOpenWhere && pickerDateRef?.current?.show()
            datepickerBackRef?.current?.hide()
            isOpenBack && datepickerBackRef.current.show()
        }
    }, [ticketPriceData, currency])

    useEffect(() => {
        if (!loadingGetRecommendation && !getRecommendationSuc) {
            if (!datePickerBack) {
                var isOpen = pickerDateRef.current?.isShown()
                // var isOpenStrong = pickerDateRefStrong.current.isShown()
                pickerDateRef.current?.hide()
                // pickerDateRefStrong.current.hide()
                datepickerBackRef.current?.hide()
                isOpen && pickerDateRef.current.show()
                // isOpenStrong && pickerDateRefStrong.current.show()
            } else {
                var isOpenBack = datepickerBackRef.current?.isShown()
                pickerDateRef.current?.hide()
                datepickerBackRef.current?.hide()
                setDateHotelPicker2('')
                isOpenBack && datepickerBackRef.current?.show()
            }
        }
    }, [allPricePicker, currency])


    useEffect(() => {
        if (getRecommendationData) {

            const handleScroll = () => {
                const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
                const showMoreElement = document.getElementById("show_moreEl");

                if (scrollPosition + 300 >= showMoreElement.offsetTop - window.innerHeight && countGet && isTicketPagination) {
                    if (!loadingGetRecommendation) {
                        debounceDispatch(20);
                    }
                    setcountGet(false);
                }
            };

            const debouncedDispatch = debounce((value) => {
                dispatch(setCountTicket(value));
            }, 500); // Adjust the delay as needed

            const debounceDispatch = (value) => {
                debouncedDispatch(value);
            };

            window.addEventListener("scroll", handleScroll);

            return () => {
                window.removeEventListener("scroll", handleScroll);
            };
        }
    }, [getRecommendationData, loadingGetRecommendation, countGet, isTicketPagination]);


    useEffect(() => {
        const parts = params.id?.split("&");

        if (params.id && (parts?.length >= 8)) {
            const fromCode = parts[0];
            const toCode = parts[1];

            setMyAirlinesCode(fromCode);
            setMyAirlinesCodeTo(toCode);

            searchAirportsFnc(fromCode);
            searchAirportsToFnc(toCode);

            // Split the params string to extract dateFrom and dateTo
            // Convert to Date objects if needed
            let givenDate1 = parts && parts[2];  // Date object for dateFrom
            let givenDate2 = parts?.length === 9 && parts && parts[3]; // Date object for dateTo

            if (givenDate1) {
                // Handle future date logic
                setDateHotelPicker1(givenDate1);  // If needed
            }
            if (givenDate2) {
                setDateHotelPicker2(givenDate2)
            }
            fromCode && toCode && givenDate1 && setAutoGet(true)
        }
    }, [params.id]);


    useEffect(() => {
        if (myAirlinesCode && myAirlinesCodeTo) {
            try {
                const Data = {
                    segments:
                    {
                        from: myAirlinesCode,
                        to: myAirlinesCodeTo
                    }
                };
                getTicketPrice(Data)

            } catch (error) {
                console.log(error);
            }
        }
    }, [myAirlinesCode, myAirlinesCodeTo])


    useEffect(() => {
        if (popularCitesName) {
            popularCitesName.price_uzs && setMyAirlines(popularCitesName.from_city_en);
            popularCitesName.price_uzs && setMyAirlinesCode(popularCitesName.from_iata);
            popularCitesName.price_uzs && setMyAirlinesCountry(popularCitesName.country_from_en)
            setMyAirlinesTo(popularCitesName.price_uzs ? popularCitesName.to_en : popularCitesName[`to_city_${lang}`]);
            setMyAirlinesCodeTo(popularCitesName.to_iata);
            setMyAirlinesCountryTo(popularCitesName.country_to_en)
        }
    }, [popularCitesName])

    useEffect(() => {
        if (getRecommendationData) {
            dispatch(setGetRecommendationData(getRecommendationData))
        }
    }, [getRecommendationData])

    useEffect(() => {
        dispatch(setGetRecommendationErr(getRecommendationErr))
    }, [getRecommendationErr])

    useEffect(() => {
        dispatch(setLoadingGetRecommendation(loadingGetRecommendation))
    }, [loadingGetRecommendation])

    useEffect(() => {
        dispatch(setGetRecommendationSuc(getRecommendationSuc))
    }, [getRecommendationSuc])

    useEffect(() => {
        if (getMyCiryErr) {
            setLoader(false)
        }
    }, [getMyCiryErr])

    const reverseAirportName = () => {
        let myAirlinesToDef = myAirlinesTo
        let myAirlinesDef = myAirlines
        let myAirlinesCodeDef = myAirlinesCode
        let myAirlinesCodeToDef = myAirlinesCodeTo

        setMyAirlinesTo(myAirlinesDef)
        setMyAirlines(myAirlinesToDef)
        setMyAirlinesCode(myAirlinesCodeToDef)
        setMyAirlinesCodeTo(myAirlinesCodeDef)
    }

    useEffect(() => {
        if (getMyCitySuc && !params.id) {
            if (getMyCityData[0]?.code) {
                setMyAirlines(getMyCityData[0]?.name);
                setMyAirlinesCode(getMyCityData[0]?.code);
                setMyAirlinesCountry(getMyCityData[0]?.country_name);
            }
            setLoader(false);
        }
    }, [getMyCityData, myCityChange])

    useEffect(() => {
        if (myAirlines) {
            setMyCityChangeLang(true)
            searchAirportsFnc(myAirlinesCode)
        }
    }, [lang])

    useEffect(() => {
        if (myAirlines || myAirlinesTo) {
            setMyCityChangeLangTo(true)
            searchAirportsToFnc(myAirlinesCodeTo)
        }
    }, [lang])

    const clearSearchLine = (inx) => {
        // document.getElementById(`searchLine${inx - 1}`).style.display = 'none'
        var filterArr = strongFlyArr.filter(item => (item !== inx))
        dispatch(setStrongFlyArr(filterArr))
        delete formData[`from${inx}`];
        delete formData[`date${inx}`];
        delete formData[`fromActive${inx}`];
        delete formData[`fromCode${inx}`];
        delete formData[`to${inx}`];
        delete formData[`toActive${inx}`];
        delete formData[`toCityIataCode${inx}`];
        delete formData[`toCode${inx}`];
    }


    const calendarClear = (e, type) => {
        e.preventDefault()
        if (!datePickerBack) {
            pickerDateRef.current.clear()
            // pickerDateRefStrong.current.clear()
            setDateHotelPicker1('')
        } else {
            if (type === 'pick2') {
                setDateHotelPicker2('')
                dispatch(setDatePickerBack(false))
                if (dateHotelPicker1) {
                    // const reverseDate = dateHotelPicker1.split('-').reverse('').join('-')
                    pickerDateRef.current.setDate(dateHotelPicker1)
                    // pickerDateRefStrong.current.setDate(reverseDate)
                }
            } else {
                setDateHotelPicker1('')
                setDateHotelPicker2('')
                datepickerBackRef.current.clear()
            }
        }
    }

    const pickerBackSwitch = (condition) => {
        if (!condition) {
            setDateHotelPicker2('')
            pickerDateRef.current.clear()
            if (dateHotelPicker1) {
                pickerDateRef.current.setDate(dateHotelPicker1)
            }
        } else {
            setDateHotelPicker1('')
            setDateHotelPicker2('')
            datepickerBackRef.current.clear()
        }
    }

    const handleInputSticker1 = (e) => {
        const value = e.target.value;
        const filteredValue = value.replace(/[^a-zA-Zа-яА-ЯёЁ\s]/g, '');
        setMyAirlinesTo(filteredValue);
    };

    const handleInputSticker = (e) => {
        const value = e.target.value;
        const filteredValue = value.replace(/[^a-zA-Zа-яА-ЯёЁ\s]/g, '');
        setMyAirlines(filteredValue);
    };

    useEffect(() => {
        dispatch(setGetRecSuc(getRecommendationSuc))
        dispatch(setGetRecLoad(loadingGetRecommendation))
    }, [getRecommendationSuc, loadingGetRecommendation])

    useEffect(() => {
        if (dateHotelPicker1) {
            datePickerBack ? datepickerBackRef.current?.hide() : pickerDateRef.current?.hide()
        }
    }, [dateHotelPicker1])



    const handleDateChange = (inx, date) => {
        const newDates = [...dates];
        newDates[inx] = date;
        setDates(newDates);
    };


    // useEffect(() => {
    //     const strongFlyEl = document.getElementById('strongFly');
    //     const tabs = document.getElementById('tabs');
    //     if (strongFly && params.id) {
    //         tabs?.style.height = `calc(50px + ${strongFlyEl?.offsetHeight}px)`;
    //     } else {
    //         tabs?.style.height = 'auto';
    //     }
    // }, [strongFly, strongFlyArr]);


    useEffect(() => {
        if (params.id && params.id.length === 45) {
            dispatch(setDatePickerBack(true))
        }
        else if (params.id && params.id.length === 27) {
            dispatch(setDatePickerBack(false))
        }
    }, [])



    useEffect(() => {
        const parts = params.id?.split("!!!");

        if (autoGet && parts?.length === 6) {
            getRecommendationDifficult()
        } else if (autoGet) {
            getRecommendationFnc('for_btn');
        }
    }, [autoGet])


    const handleSearch = () => {
        // Validate input fields
        const isFromAirlineValid = !!myAirlinesCode;
        const isToAirlineValid = !!myAirlinesCodeTo;
        const isDepartureDateValid = !!dateHotelPicker1;
        const isReturnDateValid = datePickerBack ? !!dateHotelPicker2 : true; // Only check return date if round trip
        const arePassengersValid = ticketAdults > 0; // Check if at least one adult


        if (!isFromAirlineValid || !isToAirlineValid || !isDepartureDateValid || !arePassengersValid) {
            // Show error message
            if (!isFromAirlineValid) {
                setMyAirlinesInpErr(true)
                
            }
            else if (!isToAirlineValid) {
                dispatch(setMyAirlinesToInpErr(true))
                
            }
            else if (!isDepartureDateValid) {
                setMyAirlinesDateInpErr(true)
            }
            toast.error(t("Please fill in all required fields."));
            return;
        }

        // If all validations pass, navigate to search page
        const searchPath = datePickerBack
            ? `/search/${myAirlinesCode}&${myAirlinesCodeTo}&${dateHotelPicker1}&${dateHotelPicker2}&${ticketAdults}&${ticketChild}&${ticketBabies}&${ticketTarif}&all`
            : `/search/${myAirlinesCode}&${myAirlinesCodeTo}&${dateHotelPicker1}&${ticketAdults}&${ticketChild}&${ticketBabies}&${ticketTarif}&all`;

        setAutoGet(true)
        navigate(searchPath);
        dispatch(clearFilterAirlines());
        setMyCityChange(true)
        setMyCityToChange(true)
    };

    function base64EncodeUnicode(str) {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
            return String.fromCharCode('0x' + p1);
        }));
    }

    function base64DecodeUnicode(str) {
        return decodeURIComponent(Array.prototype.map.call(atob(str), function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    }

    const handleSearchStrongFly = () => {
        const jsonString = JSON.stringify(formData);

        // Base64 formatga Unicode qatorini to'g'ri kodlash
        const base64 = base64EncodeUnicode(jsonString);

        navigate(`/search/${base64}!!![${strongFlyArr}]!!!${ticketAdults}!!!${ticketChild}!!!${ticketBabies}!!!${ticketTarif}`);
        setAutoGet(true);
    }

    useEffect(() => {
        const parts = params?.id?.split("!!!");

        if (getSortRequest) {
            if (parts?.length === 6) {
                getRecommendationDifficult()
            } else {
                getRecommendationFnc()
            }
            dispatch(changeGetSortRequest(false))
        }
    }, [getSortRequest])


    useEffect(() => {
        const parts = params?.id?.split("!!!");

        if (parts?.length === 6) {
            dispatch(setStrongFly(true));
            dispatch(setDatePickerBack(undefined))

            const base64String = JSON.parse(base64DecodeUnicode(parts[0]));

            console.log(base64String)
            setFormData(base64String)
            dispatch(setStrongFlyArr(JSON.parse(parts[1])))
            dispatch(setStrongFlyNum(JSON.parse(parts[1]).length))
            setTicketAdult(parseInt(parts[2]))
            setTicketChild(parseInt(parts[3]))
            setTicketBabies(parseInt(parts[4]))
            setticketTarif(parts[5])
            setAutoGet(true)
        }

    }, [])



    console.log(myAirlinesToInpErr)
    return (
        <div>
            {version === 'inCanvas' ? (
                <div className="hidden  lg:block tabs" id='tabs'>
                    <>
                        <div className={`hidden lg:flex items-center mb-4 gap-3 text-[${pathname == "/" ? "white" : "black"}]`} style={{
                            // marginTop: getRecommendationSuc || loadingGetRecommendation ? "5px" : '15px',
                        }}>
                            <label onClick={() => { dispatch(setStrongFly(false)); dispatch(setDatePickerBack(false)) }} className={`flex items-center gap-1 cursor-pointer `} htmlFor="oneWay">
                                <input type="radio" checked={datePickerBack === false} className="w-6 h-6" name="flyType" id="oneWay" />
                                {t("oneWay")}
                            </label>

                            <label onClick={() => { dispatch(setStrongFly(false)); dispatch(setDatePickerBack(true)) }} className={`flex items-center gap-2 cursor-pointer `} htmlFor="roundTrip">
                                <input type="radio" checked={datePickerBack === true} className="w-6 h-6" name="flyType" id="roundTrip" />
                                {t("RoundTrip")}
                            </label>

                            <label onClick={() => { dispatch(setStrongFly(true)); dispatch(setDatePickerBack(undefined)) }} className={`flex items-center gap-2 cursor-pointer `} htmlFor="dificultFly">
                                <input type="radio" checked={strongFly} className="w-6 h-6" name="flyType" id="dificultFly" />
                                {t("dificultFly")}
                            </label>
                        </div >


                        {strongFly ?
                            <div id="strongFly" className="flex flex-col ">
                                < p >
                                    {
                                        strongFlyArr.map((item, inx) => (
                                            <StrongFly
                                                key={item}
                                                fromCodeErr={fromCodeErr}
                                                setFromCodeErr={setFromCodeErr}
                                                setAirlinesDataFrom={setAirlinesDataFrom}
                                                setMyAirlinesCode={setMyAirlinesCode}
                                                airlinesDataFrom={airlinesDataFrom}
                                                toCodeErr={toCodeErr}
                                                setToCodeErr={setToCodeErr}
                                                setDateCodeErr={setDateCodeErr}
                                                setAirlinesDataTo={setAirlinesDataTo}
                                                airlinesDataTo={airlinesDataTo}
                                                myAirlinesDateInpErr={myAirlinesDateInpErr}
                                                dateCodeErr={dateCodeErr}
                                                searchAirportsFnc={searchAirportsFnc}
                                                formData={formData}
                                                searchAirportsToFnc={searchAirportsToFnc}
                                                clearSearchLine={clearSearchLine}
                                                inx={item}
                                                setFormData={setFormData}
                                                date={dates[inx]}
                                                handleDateChange={handleDateChange}
                                                minDate={inx > 0 ? dates[inx - 1] : new Date()}
                                                strongFly={strongFly}
                                                getRecommendationSuc={getRecommendationSuc}
                                                loadingGetRecommendation={loadingGetRecommendation}
                                                windowWidth={windowWidth}
                                                strongFlyArr={strongFlyArr}

                                            />
                                        ))
                                    }
                                </p >

                                <div className="lg:grid grid-cols-3 gap-[2px] mt-[12px] hidden mb-6">
                                    <PassengerDropdown
                                        setClassModalShow={setClassModalShow} classModalShow={classModalShow}
                                        totalPassengers={totalPassengers} getPassengerText={getPassengerText}
                                        ticketTarif={ticketTarif} setticketTarif={setticketTarif}
                                        ticketAdults={ticketAdults}
                                        ticketChild={ticketChild} ticketBabies={ticketBabies}
                                        setTicketAdult={setTicketAdult} setTicketChild={setTicketChild}
                                        setTicketBabies={setTicketBabies} isBaggage={isBaggage}
                                        strongFly={strongFly}
                                        setIsBaggage={setIsBaggage}
                                        loadingGetRecommendation={loadingGetRecommendation}
                                        getRecommendationSuc={getRecommendationSuc}
                                    />

                                    <div className="w-full">
                                        <button className="bg-[#FFE9CA] text-[black] text-lg rounded-lg py-[18px] w-full" onClick={() => { dispatch(setStrongFlyNum(strongFlyNum + 1)); dispatch(setStrongFlyArr([...strongFlyArr, strongFlyNum + 1])) }}>+
                                            {
                                                lang == 'ru' && "Добавить перелет"
                                            }
                                            {
                                                lang == 'uz' && "Boshqa parvozni qo'shish"
                                            }
                                            {
                                                lang == 'en' && "Add another flight"
                                            }
                                        </button>
                                    </div>
                                    <div className="w-full">
                                        <button className="bg-custom-gradient text-[#1c1c1c] text-lg rounded-lg py-[18px] w-full" onClick={(e) => (
                                            handleSearchStrongFly('strongFly'), dispatch(clearFilterAirlines()))
                                        }> {t("search")} </button>
                                    </div>
                                </div>

                            </div >
                            : (
                                <>
                                    <div className='h-[66px]  hidden lg:flex items-center'>
                                        <div className="h-full w-full flex items-center">
                                            <div className="relative col-4 h-full w-full" style={{ padding: '0' }}>
                                                <div style={{
                                                    backgroundColor: getRecommendationSuc || loadingGetRecommendation ? "#F7F7F7" : "white"
                                                }} className={`h-full ${myAirlinesInpErr && 'border-2 border-[red]'}  w-full rounded-l-lg  border border-[#c0bfbf] px-5 flex justify-center flex-col`}>
                                                    <input
                                                        type="text"
                                                        placeholder={t("from")}
                                                        value={myAirlines}
                                                        onChange={(e) => {
                                                            handleInputSticker(e);
                                                            handleFromInputChange(e);
                                                        }}
                                                        className={`${myAirlinesInpErr && 'placeholder-red-700 '} font-medium w-full bg-transparent capitalize outline-none`}
                                                        onKeyPress={(e) => {
                                                            const charCode = e.charCode;
                                                            const validChars =
                                                                (charCode >= 65 && charCode <= 90) || // A-Z
                                                                (charCode >= 97 && charCode <= 122) || // a-z
                                                                (charCode >= 1040 && charCode <= 1103) || // А-Я, а-я
                                                                charCode === 1025 || // Ё
                                                                charCode === 1105 || // ё
                                                                charCode === 32; // space

                                                            if (!validChars) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        onPaste={(e) => {
                                                            const pasteContent = e.clipboardData.getData('text');
                                                            if (!/^[a-zA-Zа-яА-ЯёЁ\s]*$/.test(pasteContent)) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                    />

                                                    {myAirlines && myAirlinesCode && myAirlinesCountry && (
                                                        <p className="flex items-center text-[13px] text-[#AEAEAE]">
                                                            {myAirlinesCode}
                                                            <div className="h-[2px] w-[2px] bg-[#AEAEAE] rounded-full mx-[5px]"></div>
                                                            {myAirlinesCountry}
                                                        </p>
                                                    )}
                                                    {myAirlinesInpErr && (
                                                        <p className="text-red-700 text-[0.8rem] mt-[8px]">{t("requiredFild")}</p>
                                                    )}
                                                </div>
                                                {airlinesDataFrom?.length > 0 && ( // Check if airlinesDataFrom is not empty before rendering
                                                    <div className="searchDataList z-10">
                                                        {airlinesDataFrom.map((item, index) => (
                                                            <div key={index} className="border-b border-gray-300  flex flex-col">
                                                                <div className="hover:bg-[#3379CB] p-[16px] w-full hover:text-[#fff]" onClick={(e) => {
                                                                    e.stopPropagation();  // Prevents click event from bubbling up to parent elements
                                                                    setMyAirlines(item.cityName);
                                                                    setMyAirlinesCode(item.cityIataCode);
                                                                    setMyAirlinesCountry(item.countryName);
                                                                    setAirlinesDataFrom([]);
                                                                }}>
                                                                    <p className="font-semibold">
                                                                        {item.cityName}, <span className="text-[#AEAEAE] font-normal">{item.countryName}</span>
                                                                    </p>
                                                                    <p className="text-[#AEAEAE] font-medium">{item.cityIataCode}</p>
                                                                </div>
                                                                <div className="flex flex-col">
                                                                    {
                                                                        item.airports && item.airports.length > 0 && item.airports.map((airport, airInx) => (
                                                                            <div
                                                                                key={airInx}
                                                                                onClick={() => {
                                                                                    setMyAirlines(airport.airportName);
                                                                                    setMyAirlinesCode(airport.airportIataCode);
                                                                                    setMyAirlinesCountry(item.cityIataCode);
                                                                                    setAirlinesDataFrom([]);
                                                                                }}
                                                                                className="pr-[16px] pl-[40px] hover:bg-[#3379CB] hover:text-[#fff] py-[13px] flex items-center justify-between "
                                                                            >
                                                                                <p className="flex items-center gap-[4px]">
                                                                                    <img loading="lazy" src={airportAriline} alt="" />
                                                                                    {airport.airportName}
                                                                                </p>
                                                                                <p className="text-[#AEAEAE] font-medium">{airport.airportIataCode}</p>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        ))}

                                                    </div>


                                                )}
                                            </div>
                                            <div style={{
                                                backgroundColor: getRecommendationSuc || loadingGetRecommendation ? "#F7F7F7" : "white"
                                            }} className='h-full  border  border-[#c0bfbf] outline-none bg-white w-[20%]  flex items-center justify-center'>
                                                <img loading="lazy" src={arrowSwap} className="cursor-pointer  lg:w-30 lg:h-30" alt="" onClick={() => reverseAirportName()} />
                                            </div>
                                            <div style={{
                                                padding: "0"
                                            }} className="relative col-4 h-full w-full" >
                                                <div style={{
                                                    backgroundColor: getRecommendationSuc || loadingGetRecommendation ? "#F7F7F7" : "white",
                                                }} className={`h-full w-full ${myAirlinesToInpErr && 'border-2 border-[red]'}  border border-[#c0bfbf] px-5 flex  justify-center flex-col `}>
                                                    <input
                                                        onKeyPress={(e) => {
                                                            const charCode = e.charCode;
                                                            const validChars =
                                                                (charCode >= 65 && charCode <= 90) || // A-Z
                                                                (charCode >= 97 && charCode <= 122) || // a-z
                                                                (charCode >= 1040 && charCode <= 1103) || // А-Я, а-я
                                                                charCode === 1025 || // Ё
                                                                charCode === 1105 || // ё
                                                                charCode === 32; // space

                                                            if (!validChars) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        onPaste={(e) => {
                                                            const pasteContent = e.clipboardData.getData('text');
                                                            if (!/^[a-zA-Zа-яА-ЯёЁ\s]*$/.test(pasteContent)) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        className={`${myAirlinesToInpErr && 'placeholder-red-700'} font-medium ticketToInput  bg-transparent w-full outline-none`}
                                                        type="text"
                                                        placeholder={t("where")}
                                                        value={myAirlinesTo || ''}
                                                        onChange={(e) => {
                                                            setMyAirlinesTo(e.target.value);
                                                            handleInputSticker1(e);
                                                            searchAirportsToFnc(e.target.value);
                                                            dispatch(setMyAirlinesToInpErr(false))
                                                        }}
                                                    />

                                                    {
                                                        myAirlinesTo && myAirlinesCodeTo && myAirlinesCountryTo ?
                                                            <p className="flex items-center text-[13px] text-[#AEAEAE]">{myAirlinesCodeTo} <div className="h-[2px] w-[2px] bg-[#AEAEAE] rounded-full mx-[5px]"></div> {myAirlinesCountryTo} </p>
                                                            : ""
                                                    }
                                                    {myAirlinesToInpErr && <p className="text-red-700 text-[0.8rem] mt-[8px]">{t("requiredFild")}</p>}
                                                </div>
                                                {airlinesDataTo && (
                                                    <div className="searchDataList2 z-10">
                                                        {airlinesDataTo.map((item, index) => (
                                                            <div key={index} className="border-b border-gray-300  flex flex-col">
                                                                <div className="hover:bg-[#3379CB] p-[16px] w-full hover:text-[#fff]" onClick={(e) => {
                                                                    e.stopPropagation();  // Prevents click event from bubbling up to parent elements
                                                                    setMyAirlinesTo(item.cityName);
                                                                    setMyAirlinesCodeTo(item.cityIataCode);
                                                                    setMyAirlinesCountryTo(item.countryName);
                                                                    setAirlinesDataTo([]);
                                                                }}>
                                                                    <p className="font-semibold">
                                                                        {item.cityName}, <span className="text-[#AEAEAE] font-normal">{item.countryName}</span>
                                                                    </p>
                                                                    <p className="text-[#AEAEAE] font-medium">{item.cityIataCode}</p>
                                                                </div>
                                                                <div className="flex flex-col">
                                                                    {
                                                                        item.airports && item.airports.length > 0 && item.airports.map((airport, airInx) => (
                                                                            <div
                                                                                key={airInx}
                                                                                onClick={() => {
                                                                                    setMyAirlinesTo(airport.airportName);
                                                                                    setMyAirlinesCodeTo(airport.airportIataCode);
                                                                                    setMyAirlinesCountryTo(item.cityIataCode);
                                                                                    setAirlinesDataTo([]);
                                                                                }}
                                                                                className="pr-[16px] pl-[40px] hover:bg-[#3379CB] hover:text-[#fff] py-[13px] flex items-center justify-between "
                                                                            >
                                                                                <p className="flex items-center gap-[4px]">
                                                                                    <img loading="lazy" src={airportAriline} alt="" />
                                                                                    {airport.airportName}
                                                                                </p>
                                                                                <p className="text-[#AEAEAE] font-medium">{airport.airportIataCode}</p>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>

                                            <div className="col-4 h-full w-full" style={{
                                                padding: "0"
                                            }}>
                                                <label htmlFor="">
                                                    {windowWidth > 1024 && (
                                                        <div style={{
                                                            backgroundColor: getRecommendationSuc || loadingGetRecommendation ? "#F7F7F7" : "white",
                                                        }} className={`${myAirlinesDateInpErr && 'border-2 border-[rgb(255,0,0)]'}  h-full w-full border  flex items-center justify-between   border-[#c0bfbf]  outline-none  ${loadingGetRecommendation || getRecommendationSuc ? 'bg-[#F7F7F7]' : 'bg-white'}`}>
                                                            <>
                                                                <input
                                                                    ref={myInputRef}
                                                                    // onFocus={scrollToInput}
                                                                    readOnly id="datepicker" style={{ display: datePickerBack && 'none' }} value={dateHotelPicker1} placeholder={t("when")} className={`${myAirlinesDateInpErr && 'placeholder-red-700'}  ${loadingGetRecommendation || getRecommendationSuc ? 'bg-[#F7F7F7]' : 'bg-white'} cursor-pointer  w-full outline-none h-[60px] px-5`} />
                                                                < input
                                                                    ref={myInputRef}
                                                                    // onFocus={scrollToInput}
                                                                    readOnly id="datepickerRange" style={{ display: !datePickerBack && 'none' }} placeholder={t("when")} className={`${myAirlinesDateInpErr && 'placeholder-red-700'}  ${loadingGetRecommendation || getRecommendationSuc ? 'bg-[#F7F7F7]' : 'bg-white'} w-full outline-none cursor-pointer  h-[60px] px-5`} value={dateHotelPicker1} />
                                                            </>
                                                            <div className="mr-[10px]">
                                                                {
                                                                    dateHotelPicker1 == '' || dateHotelPicker1 == undefined ? '' :
                                                                        <img loading="lazy" onClick={(e) => calendarClear(e, 'pick1')} src={closeCircle} alt="" />
                                                                }
                                                            </div>
                                                        </div>
                                                    )}
                                                    {myAirlinesDateInpErr && <p className="text-red-700 text-[0.8rem] ml-5 mt-[8px] translate-y-[-30px]">{t("requiredFild")}</p>}
                                                </label>
                                            </div>
                                            <div style={{
                                                backgroundColor: getRecommendationSuc || loadingGetRecommendation ? "#F7F7F7" : "#fff",
                                                display: !datePickerBack && 'none'
                                            }} className="h-full w-full flex items-center justify-between border px-3  border-[#c0bfbf] outline-none col-4" >
                                                <input
                                                    ref={myInputRef}
                                                    style={{
                                                        backgroundColor: getRecommendationSuc || loadingGetRecommendation ? "#F7F7F7" : "#fff",
                                                        cursor: !datePickerBack && 'not-allowed'
                                                    }} readOnly type="text" value={datePickerBack ? dateHotelPicker2 : ''} placeholder={t("comeBack")} className=' w-full cursor-pointer  outline-none  h-[60px]' onClick={() => { datePickerBack && datepickerBackRef.current.show(); setDatePickerBack(true) }} />
                                                < div className="" >
                                                    {
                                                        dateHotelPicker2 == '' || dateHotelPicker2 == undefined ? '' :
                                                            <img loading="lazy" onClick={(e) => calendarClear(e, 'pick2')} src={closeCircle} alt="" />
                                                    }
                                                </div>

                                            </div >
                                            <PassengerDropdown
                                                setClassModalShow={setClassModalShow} classModalShow={classModalShow}
                                                totalPassengers={totalPassengers} getPassengerText={getPassengerText}
                                                ticketTarif={ticketTarif} setticketTarif={setticketTarif} ticketAdults={ticketAdults}
                                                ticketChild={ticketChild} ticketBabies={ticketBabies}
                                                setTicketAdult={setTicketAdult} setTicketChild={setTicketChild}
                                                setTicketBabies={setTicketBabies} setIsBaggage={setIsBaggage}
                                                isBaggage={isBaggage} strongFly={strongFly}
                                                loadingGetRecommendation={loadingGetRecommendation}
                                                getRecommendationSuc={getRecommendationSuc}
                                            />
                                            <div className='flex items-center justify-center col-4 w-full ml-[15px]'>
                                                <div className='flex items-center justify-center col-4 w-full ml-[15px]'>
                                                    <button className='bg-custom-gradient text-[black] font-medium rounded-lg mr-3 h-[66px] w-full' onClick={handleSearch}>
                                                        {t("search")}
                                                    </button>
                                                </div>
                                            </div>

                                        </div >
                                    </div >

                                </>
                            )
                        }
                    </>
                </div>
            ) : version === 'outsideCanvas' && (
                <div className="container mx-auto">
                    <div
                        style={{
                            display: getRecommendationSuc || loadingGetRecommendation || windowWidth > 1024 ? "none" : "flex ",
                        }} className="flex mx-[10px] mt-5 lg:hidden flex-col bg-white p-[12px] shadow-[0px_0px_10px_2px_#00000024] rounded-[16px]">
                        <p className="mb-[24px] text-[13px] text-[#3379CB] flex items-center gap-[4px]"><img loading="lazy" src={airplane2} alt="" />{t("bronTicket")}</p>
                        < div className='flex lg:hidden flex-col'>
                            <div className='p-[15px] relative flex flex-col bg-white border border-[#c0bfbf] rounded-lg'>
                                <div onClick={() => handleOpen()} className="w-full flex flex-col">
                                    {
                                        myAirlines && myAirlinesCode &&
                                        <div className="text-[13px] flex items-center gap-[7px] ">
                                            <p className="text-[#AEAEAE]">
                                                {t("from")}
                                            </p>
                                            <div className="bg-[black] w-[5px] h-[5px] rounded-full"></div>
                                            <p>
                                                {myAirlinesCode}
                                            </p>
                                        </div>
                                    }
                                    <p className={`${myAirlines ? 'text-[rgb(0,0,0)]' : 'text-[#9DA3AE]'} text-lg`}>
                                        {myAirlines || t("from")}
                                    </p>
                                </div>
                                <div className='flex w-full items-center relative'>
                                    <div className='h-[1px] bg-[#c0bfbf] w-full'></div>
                                    <div className='ml-[5px]'>
                                        <img loading="lazy" className='' src={arrowSwap2Black} alt="" onClick={() => reverseAirportName()} />
                                    </div>
                                </div>
                                {
                                    myAirlinesTo && myAirlinesCodeTo &&
                                    <div className="text-[13px] flex items-center gap-[7px] ">
                                        <p className="text-[#AEAEAE]">
                                            {t("where")}
                                        </p >
                                        <div className="bg-[black] w-[5px] h-[5px] rounded-full"></div>
                                        <p>
                                            {myAirlinesCodeTo}
                                        </p>
                                    </div >
                                }
                                <div onClick={() => handleOpen2()} className="w-full">
                                    <p className={`${myAirlinesTo ? 'text-[black]' : 'text-[#9DA3AE]'} text-lg`}>{myAirlinesTo || t("where")}</p>
                                </div>
                            </div>
                            {
                                windowWidth < 1024 && (
                                    <div className='flex items-center justify-between mt-[15px] gap-3'>
                                        <div className='h-[60px] bg-white w-full flex items-center justify-between rounded-lg' style={{ display: datePickerBack && 'none' }}>
                                            <label className={` h-full w-full  border rounded-lg border-[#c0bfbf] outline-none flex items-center justify-between `}>
                                                <input id="datepicker" value={dateHotelPicker1} placeholder={t("when")} className={`${myAirlinesDateInpErr && 'placeholder-red-700'}  h-[50px] top-[50%] outline-none px-[10px]`} />
                                                < div className="mr-[10px]" >
                                                    {
                                                        dateHotelPicker1 == '' || dateHotelPicker1 == undefined ? '' :
                                                            <img loading="lazy" onClick={(e) => calendarClear(e, 'pick1')} src={closeCircle} alt="" />
                                                    }
                                                </div>
                                            </label>

                                        </div>
                                        <div className='h-[60px] bg-white w-full flex items-center justify-between rounded-lg' style={{ display: !datePickerBack && 'none' }}>
                                            <label className="h-full w-full border rounded-lg border-[#c0bfbf] outline-none flex items-center justify-between">
                                                <input id="datepickerRange" readOnly value={dateHotelPicker1} placeholder={t("when")} className={`${myAirlinesDateInpErr && 'placeholder-red-700'} h-[50px] cursor-pointer w-full top-[50%] outline-none px-[10px]`} />
                                                < div className="mr-[10px]" >
                                                    {
                                                        dateHotelPicker1 == '' || dateHotelPicker1 == undefined ? '' :
                                                            <img loading="lazy" onClick={(e) => calendarClear(e, 'pick1')} src={closeCircle} alt="" />
                                                    }
                                                </div>
                                            </label>
                                        </div >
                                        <div className='h-[60px] bg-white w-full flex items-center justify-between rounded-lg' style={{ display: !datePickerBack && 'none' }}>
                                            <label className="h-full w-full border rounded-lg border-[#c0bfbf] outline-none flex items-center justify-between">
                                                <input value={dateHotelPicker2} placeholder={t("comeBack")} readOnly className={`${myAirlinesDateInpErr && 'placeholder-red-700'} cursor-pointer h-[50px] w-full top-[50%] outline-none px-[10px]`} onClick={() => datepickerBackRef.current.show()} />
                                                < div className="mr-[10px]" >
                                                    {
                                                        dateHotelPicker2 == '' || dateHotelPicker2 == undefined ? '' :
                                                            <img loading="lazy" onClick={(e) => calendarClear(e, 'pick2')} src={closeCircle} alt="" />
                                                    }
                                                </div>
                                            </label >
                                        </div >
                                    </div >
                                )
                            }

                            <PassengerDropdown
                                setClassModalShow={setClassModalShow} classModalShow={classModalShow}
                                totalPassengers={totalPassengers} getPassengerText={getPassengerText}
                                ticketTarif={ticketTarif} setticketTarif={setticketTarif} ticketAdults={ticketAdults}
                                ticketChild={ticketChild} ticketBabies={ticketBabies}
                                setTicketAdult={setTicketAdult} setTicketChild={setTicketChild}
                                setTicketBabies={setTicketBabies} setIsBaggage={setIsBaggage}
                                isBaggage={isBaggage} strongFly={strongFly}
                                loadingGetRecommendation={loadingGetRecommendation}
                                getRecommendationSuc={getRecommendationSuc}
                            />
                            <div>
                                <button className='bg-custom-gradient text-[#1c1c1c] font-bold rounded-lg mt-3 lg:mr-3  h-[66px] w-full' onClick={(e) => (handleSearch(), dispatch(clearFilterAirlines()))}>
                                    {t("search")}
                                </button>
                                {windowWidth < 1024 && (
                                    <div className="mt-1 flex items-center">
                                        <Switch checked={datePickerBack} onChange={(e) => { dispatch(setDatePickerBack(e.target.checked)); pickerBackSwitch(e.target.checked) }} inputProps={{ 'aria-label': 'controlled' }} color="default" defaultChecked />
                                        <p>{t("RoundTrip")}</p>
                                    </div>
                                )}
                            </div>

                        </div >
                    </div >
                    <FromModal
                        setOpen2={setOpen2} setOpen3={setOpen3}
                        open={open} setOpen={setOpen} handleClose={handleClose}
                        myAirlinesInpErr={myAirlinesInpErr} myAirlines={myAirlines}
                        setMyAirlines={setMyAirlines} searchAirportsFnc={searchAirportsFnc}
                        airlinesDataFrom={airlinesDataFrom} setMyAirlinesCode={setMyAirlinesCode}
                        setMyAirlinesCountry={setMyAirlinesCountry} setAirlinesDataFrom={setAirlinesDataFrom}
                        airportAriline={airportAriline}
                    />
                    <WhereModal
                        setOpen2={setOpen2} setOpen3={setOpen3}
                        open2={open2} myAirlinesTo={myAirlinesTo}
                        setMyAirlinesTo={setMyAirlinesTo}
                        searchAirportsToFnc={searchAirportsToFnc} airlinesDataTo={airlinesDataTo}
                        setMyAirlinesCodeTo={setMyAirlinesCodeTo} setMyAirlinesCountryTo={setMyAirlinesCountryTo}
                        setAirlinesDataTo={setAirlinesDataTo} airportAriline={airportAriline}
                        setPopularCities={setPopularCities} setPopularCitesCode={setPopularCitesCode}

                    />
                    <StrongFlyModal
                        open3={open3} handleClose3={handleClose3}
                        handleOpen2={handleOpen2} strongFlyNum={strongFlyNum}
                        fromCodeErr={fromCodeErr} setAirlinesDataFrom={setAirlinesDataFrom}
                        setMyAirlinesCode={setMyAirlinesCode} airlinesDataTo={airlinesDataTo}
                        myAirlinesDateInpErr={myAirlinesDateInpErr} dateCodeErr={dateCodeErr}
                        searchAirportsFnc={searchAirportsFnc} clearSearchLine={clearSearchLine}
                        setFormData={setFormData} setStrongFlyNum={setStrongFlyNum}
                        strongFlyArr={strongFlyArr} setClassModalShow={setClassModalShow}
                        classModalShow={classModalShow} ticketAdults={ticketAdults}
                        ticketChild={ticketChild} ticketBabies={ticketBabies}
                        airlinesDataFrom={airlinesDataFrom} toCodeErr={toCodeErr}
                        setAirlinesDataTo={setAirlinesDataTo} formData={formData}
                        searchAirportsToFnc={searchAirportsToFnc} setStrongFlyArr={setStrongFlyArr}
                        getPassengerText={getPassengerText} totalPassengers={totalPassengers}
                        ticketTarif={ticketTarif} setticketTarif={setticketTarif} setTicketAdult={setTicketAdult}
                        setTicketChild={setTicketChild} setTicketBabies={setTicketBabies}
                        setIsBaggage={setIsBaggage} isBaggage={isBaggage}
                        getRecommendationDifficult={getRecommendationDifficult} strongFly={strongFly}
                    />
                </div >
            )}
        </div>
    )
}
