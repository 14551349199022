import React, { useEffect } from 'react'
import { Fade, Modal } from "@mui/material";
import Box from '@mui/material/Box';
import leftArrBlue from "@assets/icons/arrow-leftBlue.svg"
import { useTranslation } from 'react-i18next';
import shareWhite from "@assets/icons/shareWhite.svg"
import { useDispatch, useSelector } from 'react-redux';
import { usePopularCitesMutation } from '../../services/AllApi';

const style = {
    position: 'absolute',
    bottom: '0',
    left: '0',
    width: '100%',
    bgcolor: 'background.paper',
    boxShadow: '0px -24px 24px rgba(0, 0, 0, 0.2)',
    padding: '20px',
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    height: "90vh",
    overflowY: "scroll",
    transition: '1s'
};

function WhereModal({ setOpen2, open2, setOpen3, myAirlinesTo, setMyAirlinesTo, searchAirportsToFnc, airlinesDataTo, setMyAirlinesCodeTo, setMyAirlinesCountryTo, setAirlinesDataTo, airportAriline, setPopularCities, setPopularCitesCode }) {
    const handleClose2 = () => setOpen2(false);
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const lang = useSelector((state) => state.AllSlice.lang)
    const currency = useSelector((state) => state.AllSlice.currency);
    const [popularCites, { data: popularCitesData, isSuccess: popularCitesSuc }] = usePopularCitesMutation()
    const handleOpen3 = () => setOpen3(true);

    const formatCurrency = (number, currency) => {
        return new Intl.NumberFormat({ style: 'currency', currency }).format(number);
    };

    useEffect(() => {
        try {
            popularCites({})
        } catch (error) { console.log(error); }
    }, [])

    const toLangKey = `to_${lang}`;
    const toLangEn = `to_en`;
    const priceLangKey = `price_${currency.toLowerCase()}`;

    return (
        <Modal
            open={open2}
            onClose={handleClose2}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
            BackdropProps={{
                timeout: 1500,
            }}
        >
            <Fade in={open2}>
                <Box sx={style}>
                    <div className="flex items-center">
                        <img loading="lazy" onClick={() => handleClose2()} src={leftArrBlue} alt="" />
                        <input autoFocus className='outline-none px-[10px]' type="text" placeholder={t("where")} value={myAirlinesTo || ''} onChange={(e) => { setMyAirlinesTo(e.target.value); searchAirportsToFnc(e.target.value) }} />
                    </div>
                    <div className="px-[16px] py-[24px]">
                        <button onClick={() => { handleOpen3(); handleClose2() }} className="bg-[#27AE60] mb-[32px] text-5 w-full rounded-lg text-[#fff] py-[5px] flex items-center justify-center"> <img loading="lazy" className="mr-[12px]" src={shareWhite} alt="" />{t("dificultFly")}</button>
                        <div className='grid grid-cols-1 '>
                            {
                                airlinesDataTo ?
                                    airlinesDataTo?.map((item, index) =>
                                        <div key={index} className="border-b border-gray-300  flex flex-col">
                                            <div className="hover:bg-[#3379CB] p-[16px] w-full hover:text-[#fff] flex items-center justify-between" onClick={(e) => {
                                                e.stopPropagation();  // Prevents click event from bubbling up to parent elements
                                                setMyAirlinesTo(item.cityName);
                                                setMyAirlinesCodeTo(item.cityIataCode);
                                                setMyAirlinesCountryTo(item.countryName);
                                                setAirlinesDataTo([]);
                                                handleClose2()
                                            }}>
                                                <p className="font-semibold">
                                                    {item.cityName}, <span className="text-[#AEAEAE] font-normal">{item.countryName}</span>
                                                </p>
                                                <p className="text-[#AEAEAE] font-medium">{item.cityIataCode}</p>
                                            </div>
                                            <div className="flex flex-col">
                                                {
                                                    item.airports && item.airports.length > 0 && item.airports.map((airport, airInx) => (
                                                        <div
                                                            key={airInx}
                                                            onClick={() => {
                                                                setMyAirlinesTo(airport.airportName);
                                                                setMyAirlinesCodeTo(airport.airportIataCode);
                                                                setMyAirlinesCountryTo(item.cityIataCode);
                                                                setAirlinesDataTo([]);
                                                                handleClose2()
                                                            }}
                                                            className="pr-[16px] pl-[40px] hover:bg-[#3379CB] hover:text-[#fff] py-[13px] flex items-center justify-between "
                                                        >
                                                            <p className="flex items-center gap-[4px]">
                                                                <img loading="lazy" src={airportAriline} alt="" />
                                                                {airport.airportName}
                                                            </p>
                                                            <p className="text-[#AEAEAE] font-medium">{airport.airportIataCode}</p>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    ) :
                                    <div className='grid  gap-5  grid-cols-1 '>
                                        {popularCitesSuc && popularCitesData.map((item, inx) => (
                                            <div
                                                onClick={() => {
                                                    dispatch(setPopularCities(item));
                                                    handleClose2()
                                                }}
                                                className='flex items-center gap-4  cursor-pointer'
                                                key={inx}>
                                                <div className=''>
                                                    <img loading="lazy"
                                                        className={`rounded-lg w-[80px] h-[80px]`}
                                                        // src={`https://gw.flysells.uz${item.img}`}
                                                        src={`${item.img}`}
                                                        alt="..."
                                                    />
                                                </div>
                                                <div className='flex flex-col'>
                                                    <h4 className='text-5 font-bold lg:mb-[5px] text-[#2E5B8B]' >{item[toLangKey]}</h4>
                                                    <p className='text-[#AEAEAE] text-[16px] flex items-center gap-[2px]'>
                                                        {t("to")} {item[priceLangKey] && formatCurrency(item[priceLangKey], currency)}
                                                        < p > {currency == 'RUB' && '₽'}</p>
                                                        <p>{currency == 'UZS' && t("sum")}</p>
                                                        < p > {currency == 'USD' && '$'}</p>
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                            }
                        </div>
                    </div>
                </Box>
            </Fade>
        </Modal >
    )
}

export default WhereModal