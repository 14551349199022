import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
    jdticketData: undefined,
    jdgetRecommendationSuc: undefined,
    jdloadingGetRecommendation: undefined,
    jdgetRecommendationData: undefined,
    jdgetRecommendationErr: undefined,
    search_id: undefined,
    adults: 1,
    children: 0,
    train_number: undefined,
    departure_date_time: undefined,
    jdticketInfoData: undefined,
    selectedJdItem: undefined,
    wagonInfo: []
}

export const slice = createSlice({
    name: 'JdSlice',
    initialState,
    reducers: {
        setJdticketData: (state, action) => {
            state.jdticketData = action.payload
        },
        setJdgetRecommendationSuc: (state, action) => {
            state.jdgetRecommendationSuc = action.payload
        },
        setJdloadingGetRecommendation: (state, action) => {
            state.jdloadingGetRecommendation = action.payload
        },
        setJdgetRecommendationData: (state, action) => {
            state.jdgetRecommendationData = action.payload
        },
        setJdgetRecommendationErr: (state, action) => {
            state.jdgetRecommendationErr = action.payload
        },
        setTrain_number: (state, action) => {
            state.train_number = action.payload
        },
        setDeparture_date_time: (state, action) => {
            state.departure_date_time = action.payload
        },
        setSearch_id: (state, action) => {
            state.search_id = action.payload
        },
        addAdults: (state, action) => {
            state.adults = state.adults + 1
        },
        subtractAdults: (state, action) => {
            state.adults = state.adults - 1
        },
        addChildren: (state, action) => {
            state.children = state.children + 1
        },
        subtractChildren: (state, action) => {
            state.children = state.children - 1
        },
        setJdticketInfoData: (state, action) => {
            state.jdticketInfoData = action.payload
        },
        setSelectedJdItem: (state, action) => {
            state.selectedJdItem = action.payload
        },
        setWagonInfo: (state, action) => {
            state.wagonInfo = action.payload
        },
    }
})

export const { setJdticketData, setJdgetRecommendationSuc, setJdloadingGetRecommendation, setJdgetRecommendationData, setJdgetRecommendationErr, setSearch_id, addAdults, subtractAdults, addChildren, subtractChildren, setDeparture_date_time, setTrain_number, setJdticketInfoData, setSelectedJdItem, setWagonInfo } = slice.actions

export default slice.reducer