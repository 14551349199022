import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Store } from "./Store/Store";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from '@react-oauth/google';
import ScrollToTop from "./components/scrollToTop/ScrollToTop";
import * as Sentry from "@sentry/react";
import i18n from './locales/i18n'; 
import GetglobalError from "./GetglobalError";

Sentry.init({
  dsn: "https://a5e5182d257024126391c6a3cdff3766@sentry.cloudgate.uz/3",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <GetglobalError/>
    <ScrollToTop /> {/* Add the ScrollToTop component */}
    <Provider store={Store}>
      <GoogleOAuthProvider clientId='228023497053-r9r87g7c9fd6pmqt29ukmspfg9k1hgv9.apps.googleusercontent.com'>
        <App />
      </GoogleOAuthProvider>
    </Provider>
  </BrowserRouter>
);
