import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const loggedIn = localStorage.getItem("access")
export const AllApi = createApi({
  reducerPath: "AllApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.mysafar.ru",
  }),

  endpoints: (builder) => ({
    searchAirports: builder.mutation({
      query: (airport) => ({
        url: "/avia/airports",
        method: "POST",
        body: airport,
      }),
    }),

    searchAirportsTo: builder.mutation({
      query: (airport) => ({
        url: "/avia/airports",
        method: "POST",
        body: airport,
      }),
    }),

    getRecommendation: builder.mutation({
      query: (recommendationBody) => ({
        url: "/avia/get-recommendations",
        method: "POST",
        body: recommendationBody,
        headers: {
          Authorization: loggedIn && `Bearer ${localStorage.getItem("access")}`
        }
      }),
    }),


    flightInfo: builder.mutation({
      query: (flightBody) => ({
        url: "/avia/get-flight-info",
        method: "POST",
        body: flightBody,
        headers: {
          Authorization: loggedIn && `Bearer ${localStorage.getItem("access")}`
        }
      }),
    }),

    popularCites: builder.mutation({
      query: () => ({
        url: "/main/popcites",
        method: "POST",
      }),
    }),

    popularCitesFamous: builder.mutation({
      query: () => ({
        url: "/main/popcites-famous",
        method: "POST",
      }),
    }),

    getTicketPrice: builder.mutation({
      query: (ticketPriceBody) => ({
        url: "/avia/one-month-ticket-price",
        method: "POST",
        body: ticketPriceBody,
      }),
    }),

    switchUpDown: builder.mutation({
      query: () => ({
        url: "admina/get-avia-service-switcher",
        method: "GET",
      }),
    }),

    searchRailways: builder.mutation({
      query: (railway) => ({
        url: "/railways-stations",
        method: "POST",
        body: railway,
      }),
    }),

    searchRailwaysTo: builder.mutation({
      query: (railway) => ({
        url: "/railways-stations",
        method: "POST",
        body: railway,
      }),
    }),

    railwayTrainList: builder.mutation({
      query: (railwayBody) => ({
        url: "/railways-trains-list",
        method: "POST",
        body: railwayBody,
      }),
    }),

    railwayWagonInfo: builder.mutation({
      query: (railwayBody) => ({
        url: "/railways-wagon-info",
        method: "POST",
        body: railwayBody,
      }),
    }),
    railwayWagonScheme:
      builder.mutation({
        query: (railwayBody) => ({
          url: "/railways-wagon-scheme",
          method: "POST",
          body: railwayBody,
        }),
      }),
    getContents: builder.mutation({
      query: () => ({
        url: "/content/get-contents",
        method: "Get",
      }),
    }),
  }),
});

export const { useSearchAirportsMutation, useSearchAirportsToMutation, useGetRecommendationMutation, useFlightInfoMutation, usePopularCitesMutation, usePopularCitesFamousMutation, useGetTicketPriceMutation, useSwitchUpDownMutation, useSearchRailwaysMutation, useSearchRailwaysToMutation, useRailwayTrainListMutation, useRailwayWagonInfoMutation, useRailwayWagonSchemeMutation, useGetContentsMutation } = AllApi;
