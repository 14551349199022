import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  loggedIn: localStorage.getItem('access'),
  value: 0,
  userInfo: [],
  loginModal: false,
  openForgot: false,
  openResetPas: false,
}

export const slice = createSlice({
  name: 'LoginSlice',
  initialState,
  reducers: {
    setOpenForgot: (state, action) => {
      state.openForgot = action.payload
    },
    setOpenResetPas: (state, action) => {
      state.openResetPas = action.payload
    },
    setLoginModal: (state, action) => {
      state.loginModal = action.payload
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload
    },
  }
})

export const { setLoginModal, setOpenForgot, setOpenResetPas, setUserInfo } = slice.actions

export default slice.reducer 