import i18n from "i18next"
import {initReactI18next} from "react-i18next"

import translationUZ from "./translationUz.json"
import translationRu from "./translationRu.json"
import translationEn from "./translationEn.json"
// import {I18N_LANGUAGE} from "@config/constants";

const resources = {
    uz: {
        translation: translationUZ,
    },
    ru: {
        translation: translationRu
    },
    en: {
        translation: translationEn
    },

};

const language = localStorage.getItem('lang') || "ru";

if (!language) {
    localStorage.setItem("lang", "ru")
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: localStorage.getItem('lang') || "ru",
        fallbackLng:"ru",
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;

