import React from 'react'
import { useTranslation } from 'react-i18next'



function TechnicalError() {
  const {t}=useTranslation()
  return (
    <div className="flex Down-container h-[100vh] items-center justify-center text-[30px] text-white">
   
      <div className="flex items-center flex-col">
        <h1 className="text-6 lg:text-12">{t("Technical works are being carried out on the website.")}</h1>
        <h1 className="text-6 lg:text-12"> {t("Our website will be launched soon")}</h1>
      </div>
    
  
  </div>
  )
}

export default TechnicalError