import React, { useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLoginModal } from "@Slice/LoginSlice";

const ProtectedRoutes = () => {
  const loggedIn = localStorage.getItem("access");
  const open = useSelector((state) => state.AllSlice.open);
  const openForgot = useSelector((state) => state.LoginSlice.openForgot);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loggedIn && !open && !openForgot) {
      dispatch(setLoginModal(true));
    }
  }, [loggedIn, open, openForgot, dispatch]);

  return loggedIn ? <Outlet /> : null;
};

export default ProtectedRoutes;
