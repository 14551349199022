import React, { useEffect, useMemo, useState } from "react";
// import "./Ticket.css";
import { Container, Grid, Skeleton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "./Ticket.css";
import { setTicketId, removeFilterAirlines, checkChangeAir, setArrOrder, setDepOrder, setDurationOrder, setPriceOrder, setFilterDirect, setChangeFilterDirect, addFilterAirlines, changeGetSortRequest } from "@Slice/HomeSlice";
import { useNavigate } from "react-router-dom";
import TicketSkeleton from "../TicketSkeleton/TicketSkeleton";
import moment from "moment";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import cirClose from '@assets/icons/close-circle.svg'
import airplane from '@assets/icons/airplane.svg'
import line from '@assets/icons/line.svg'
import erorr from '@assets/icons/404erorr.svg'
import Fade from '@mui/material/Fade';
import TicketAccardion from "./TicketAccardion";

import menu from "@assets/icons/menuBlack.svg"
import arrLeft from "@assets/icons/arrow-leftBalck.svg"
import arrow from "@assets/icons/arrow-leftBlue.svg"
import setting from "@assets/icons/setting-2.svg"
import TicketMap from "./TicketMap";
import Menu from '@mui/material/Menu';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import uzbAirlines from "@assets/airlines/uzbekistanAirlines.png"
import { IoAirplaneOutline } from "react-icons/io5";
import { data } from './AirlinesNameData'
import Weather from "../weather/Weather";
import { useTranslation } from "react-i18next";



const style = {
  position: 'absolute',
  bottom: '0',
  left: '0',
  width: '100%',
  bgcolor: 'background.paper',
  boxShadow: '0px -24px 24px rgba(0, 0, 0, 0.2)',
  paddingLeft: "20px",
  paddingRight: "20px",
  paddingBottom: "20px",
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
  height: "90vh",
  overflowY: "scroll",
  transition: '1s'
};

const styleSrt = {
  position: 'absolute',
  bottom: '0',
  left: '0',
  width: '100%',
  bgcolor: 'background.paper',
  boxShadow: '0px -24px 24px rgba(0, 0, 0, 0.2)',
  padding: '32px 16px',
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
  height: "auto",
  overflowY: "scroll",
  transition: '1s'
};




function Ticket(props) {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false);
  const [ticketDetail, setTicketDetail] = useState([])
  const handleOpen = () => dispatch(setOpen(true));
  const handleClose = () => dispatch(setOpen(false));
  const [windowHeight, setWindowHeight] = useState(0);
  const lang = useSelector((state) => state.AllSlice.lang)
  const TicketData = useSelector((state) => state.HomeSlice.ticketData);
  const allAirlinesName = useSelector((state) => state.HomeSlice.allAirlinesName);
  const priceOrder = useSelector((state) => state.HomeSlice.priceOrder);
  const arrOrder = useSelector((state) => state.HomeSlice.arrOrder);
  const depOrder = useSelector((state) => state.HomeSlice.depOrder);
  const durationOrder = useSelector((state) => state.HomeSlice.durationOrder);
  const filterDirect = useSelector((state) => state.HomeSlice.filterDirect);
  const filterAirlines = useSelector((state) => state.HomeSlice.filterAirlines);

  const [selectedSort, setSelectedSort] = useState('');

  // Set default value on component mount
  useEffect(() => {
    const savedSort = localStorage.getItem('rangeChecked') || 'cheep';
    setSelectedSort(savedSort);
  }, []);

  // Update localStorage whenever selectedSort changes
  useEffect(() => {
    localStorage.setItem('rangeChecked', selectedSort);
  }, [selectedSort]);

  // Update selectedSort based on props values
  useEffect(() => {
    if (Number(priceOrder) !== 0 && priceOrder == "1") {
      setSelectedSort('cheep');
    } else if (Number(priceOrder) !== 0 && priceOrder == "-1") {
      setSelectedSort('expensive');
    } else if (Number(depOrder) !== 0) {
      setSelectedSort('dep');
    }
    else if (Number(arrOrder) !== 0) {
      setSelectedSort('arr');
    } else if (Number(durationOrder) !== 0) {
      setSelectedSort('duration');
    } else {
      setSelectedSort('cheep');
    }
  }, [priceOrder, arrOrder, depOrder, durationOrder]);


  const [ticketFilterShow, setTicketFilterShow] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [skeletonNum, setSkeletonNum] = useState([]);
  const [accardionOpen, setAccardionOpen] = useState('panel');
  // const [checkedItems, setCheckedItems] = useState({});
  const [currentAirLines, setCurrentAirlines] = useState({ name: 'Uzbekistan airways', code: 'HY' })


  const toShoppingTicket = (id, price, item) => {
    if (id) {
      navigate(`/order/${id}`);
      window.scrollTo(0, 0);
      dispatch(setTicketId(id));
    }
  };

  const currencyFnc = (number, currency, lang = undefined) =>
    Intl.NumberFormat(lang, { style: "currency", currency }).format(number);

  const filterInputChange = (checkbox) => {
    if (checkbox.currentTarget.checked) {
      dispatch(addFilterAirlines(checkbox.target.value))
    } else {
      dispatch(removeFilterAirlines(checkbox.target.value))
    }
    dispatch(checkChangeAir())
  }

  useMemo(() => {
    if (TicketData || props.loading) {
      setTicketFilterShow(true)
    }
  }, [TicketData, props.loading])

  useMemo(() => {
    // 10 ta elementni tuzish
    const newItems = Array.from({ length: 3 }, (_, index) => ({
      id: index,
      content: `Element ${index + 1}`,
    }));

    setSkeletonNum(newItems);
  }, []);


  const [anchorEl, setAnchorEl] = React.useState(false);
  const [anchorElTransfer, setAnchorElTransfer] = React.useState(false);
  const [anchorElSort, setAnchorElSort] = React.useState(false);
  const [anchorElSrt, setAnchorElSrt] = React.useState(false);
  const openMenu = Boolean(anchorEl);
  const openTransfer = Boolean(anchorElTransfer);
  const openSort = Boolean(anchorElSort);
  const openSrt = Boolean(anchorElSrt);

  const handleCloseMenu = () => {
    setAnchorEl(false);
  };
  const handleCloseTransfer = () => {
    setAnchorElTransfer(false);
  };
  const handleCloseSort = () => {
    setAnchorElSort(false);
  };
  const handleCloseSrt = () => {
    setAnchorElSrt(false);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickTransfer = (event) => {
    setAnchorElTransfer(event.currentTarget);
  };
  const handleClickSort = (event) => {
    setAnchorElSort(event.currentTarget);
  };
  const handleClickSrt = (event) => {
    setAnchorElSrt(event.currentTarget);
  };

  // const handleCheckboxChange = (e) => {
  //   const { id, checked } = e.target;
  //   setCheckedItems(prevState => ({
  //     ...prevState,
  //     [id]: checked
  //   }));
  //   filterInputChange(e);
  // };



  useEffect(() => {
    let index = 0;

    const updateAirlines = () => {
      if (index < data.length) {
        setCurrentAirlines(data[index]);
        index++;
        setTimeout(updateAirlines, 200);
      } else {
        index = 0;
        setTimeout(updateAirlines, 200);
      }
    };

    updateAirlines();

    return () => {
      index = data.length;
    };
  }, [data]);

  const handleRangeChange = (e) => {
    dispatch(setPriceOrder(e.target.value == "cheep" ? "" : 0));
    dispatch(setDepOrder(e.target.value == "dep" ? '1' : ''))
    dispatch(setArrOrder(e.target.value == "arr" ? '1' : ''))
    dispatch(setDurationOrder(e.target.value == "duration" ? 1 : ''))
    localStorage.setItem("rangeChecked", e.target.value);
  }

  const handleTransferChange = (e) => {
    dispatch(setFilterDirect(e.target.value == "all" ? '0' : '1'))
    dispatch(setChangeFilterDirect(true))
  }

  const sortItems = [
    {
      id: 1,
      title_ru: "Сортировка",
      title_uz: "Saralash",
      title_en: "Filter"
    },
    {
      id: 2,
      title_ru: "Пересадки",
      title_uz: "To'xtab uchish",
      title_en: "Transfer"
    },
    {
      id: 3,
      title_ru: "Авиакомпании",
      title_uz: "Avia kopmaniyalar",
      title_en: "Avia companies"
    },

  ]

  const [showFormControl, setShowFormControl] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleClickTranStr = (index) => {
    setSelectedIndex(index);
    setShowFormControl(true);
  };

  const handleGotovClick = () => {
    setShowFormControl(false);
    setSelectedIndex(null);
  };


  const handleShowOptionsClick = () => {
    setShowFormControl(true); // Show form controls
    setSelectedIndex(0); // Set default selected index to show "Сортировка" form
  };


  const getSortRequest = () => {
    handleCloseSrt()
    dispatch(changeGetSortRequest(true))
    // props.getRecomFnc()
  }

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const scrollPosition = window.scrollY;
      setWindowHeight(scrollPosition)
    });
  }, [])

  useEffect(() => {
    console.log(filterAirlines);
  }, [filterAirlines])


  useEffect(() => {
    if (windowHeight > 70) {
      handleCloseMenu()
      handleCloseTransfer()
      handleCloseSort()
    }
  }, [windowHeight])

  const titleLangKey = `title_${lang}`

  return (
    <div
      className="aviaTicket">
      <div className="Ticket container mx-auto">
        {props.loading && (
          <div className="center mb-[30px]">
            <div className="file-transfer">
              <div>
                <div className="flex flex-col w-full items-center mb-[30px]">
                  <h1 className="text-2xl mb-2">
                    {t("Ticket is searching...")}
                  </h1>
                  {currentAirLines && (
                    <span className="text-[15px] flex items-center"><img loading="lazy" className="w-[35px] rounded-full mr-2" src={`https://mpics.avs.io/al_square/240/240/${currentAirLines.code}.png`} alt="" /> {currentAirLines.name} </span>
                  )}
                </div>
              </div>
              <div className="progress"></div>
            </div>
          </div>
        )}

        {ticketFilterShow === true && !props.reErr && (
          <>
            <div
              style={{
                display: props.windowWidth < 1024 ? "none" : "flex"
              }} className="mb-[20px]  text-[#222222] flex md:flex-nowrap   flex-wrap items-center   gap-[10px]">
              <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <button onClick={handleClickSort} className="text-[16px] bg-[#F7F7F7]  rounded-[8px] py-[8px] px-[12px] flex items-center  gap-[10px] "> <img loading="lazy" className="" src={menu} alt="" />
                  {t("Filter by")}
                </button>
              </Box>
              <Menu
                anchorEl={anchorElSort}
                id="account-menu"
                open={openSort}
                onClick={handleCloseSort}
                disableScrollLock={true}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    padding: "20px",
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    ml: "100px",
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&::before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      left: 12,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <FormControl className="">
                  <FormLabel id="demo-radio-buttons-group-label">
                    {t('Filter')}
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={localStorage.getItem('rangeChecked')}
                    name="radio-buttons-group"
                    onChange={(e) => handleRangeChange(e)}
                  >
                    <FormControlLabel value="cheep" control={<Radio />} label={t("CheepFirst")} />
                    {/* <FormControlLabel value="expensive" control={<Radio />} label="Сначала дорогие" /> */}
                    <FormControlLabel value="arr" control={<Radio />} label={t("flyDep")} />
                    <FormControlLabel value="dep" control={<Radio />} label={t("flyArr")} />
                    <FormControlLabel value="duration" control={<Radio />} label={t("duration")} />
                  </RadioGroup>
                </FormControl>

              </Menu>
              {/* <button className="text-[16px] bg-[#F7F7F7] rounded-[8px] py-[8px] px-[12px] flex items-center gap-[10px] cursor-not-allowed "> Без пересадок от <span className="text-[#AEAEAE]">4 000 000 UZS</span></button>
              <button className="text-[16px] bg-[#F7F7F7] rounded-[8px] py-[8px] px-[12px] flex items-center gap-[10px] cursor-not-allowed "> С багажом от <span className="text-[#AEAEAE]">4 000 000 UZS</span></button> */}
              <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <button onClick={handleClickTransfer} className="text-[16px] bg-[#F7F7F7] rounded-[8px] py-[8px] px-[12px] flex items-center gap-[10px]  cursor-pointer">
                  {t("peresadka")}
                  <img loading="lazy" className="rotate-[-90deg]" src={arrLeft} alt="" /></button>
              </Box>
              <Menu
                anchorEl={anchorElTransfer}
                id="account-menu"
                open={openTransfer}
                onClick={handleCloseTransfer}
                disableScrollLock={true}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    padding: "16px 20px",
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&::before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                {/* <div className="flex items-center gap-[75px]">
                  <p className="text-[20px] font-bold ">{t("transfer")}</p>
                  <p className="text-lg text-[#AEAEAE]">
                    Сбросить
                  </p>
                </div> */}
                <FormControl className="">
                  <FormLabel id="demo-radio-buttons-group-label">{t("transfer")}</FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="noTransfer"
                    value={filterDirect == 1 ? 'noTransfer' : 'all'}
                    name="radio-buttons-group"
                    onChange={(e) => handleTransferChange(e)}
                  >
                    <FormControlLabel value="all" control={<Radio />} label={t("all")} />
                    <FormControlLabel value="noTransfer" control={<Radio />} label={t("withoutTransfer")} />
                    {/* <FormControlLabel value="transfer" control={<Radio />} label="С пересадкой" /> */}
                  </RadioGroup>
                </FormControl>
              </Menu>
              {/* <button className="text-[16px] bg-[#F7F7F7] rounded-[8px] py-[8px] px-[12px] flex items-center gap-[10px] "> Время <img loading="lazy" className="rotate-[-90deg]" src={arrLeft} alt="" /></button> */}
              {/* <button className="text-[16px] bg-[#F7F7F7] rounded-[8px] py-[8px] px-[12px] flex items-center gap-[10px]  cursor-pointer"> Аеропорты <img loading="lazy" className="rotate-[-90deg]" src={arrLeft} alt="" /></button> */}
              <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <button onClick={handleClick} className="text-[16px] relative bg-[#F7F7F7] rounded-[8px] py-[8px] px-[12px] flex items-center gap-[10px] ">
                  {t("aviaCompany")}
                  <img loading="lazy" className="rotate-[-90deg]" src={arrLeft} alt="" /></button>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={openMenu}
                onClick={handleCloseMenu}
                disableScrollLock={true}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&::before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <Grid item lg={3} className=" p-[10px] checklistContainer ">
                  <div className="checklist">
                    <div className="company">

                      {allAirlinesName.length > 0 ? (
                        allAirlinesName.map((item, airIndex) =>
                          <div className="checkbox flex items-center " key={airIndex}>
                            <input type="checkbox"
                              id={item.title} value={item.code} checked={filterAirlines.includes(item.code)} onClick={(e) => { filterInputChange(e); }} />
                            <img loading="lazy" className="w-[25px] h-[25px] rounded-3xl m-[10px]" src={`https://mpics.avs.io/al_square/240/240/${item.code}.png`} alt="" />
                            <label htmlFor={item.title}>{item.title}</label>
                          </div>
                        )
                      ) : (
                        skeletonNum?.map((item, index) =>
                          <Skeleton loading={props.isLoading}>
                            <div className="checkbox">
                              <input type="checkbox" />
                              <label>Uzbekistan Air</label>
                              <label>{index === 1 && "Flydubai"}</label>
                            </div>
                          </Skeleton>
                        )
                      )}
                    </div>
                  </div>
                </Grid >
              </Menu>
            </div>
            <div
              style={{
                display: props.windowWidth > 1024 && (props.loading || props.reSuc) ? "none" : "flex",
              }}
              className="container mx-auto flex items-center justify-between py-[8px] mb-[12px]"
            >
              <img loading="lazy" onClick={() => navigate('/')} src={arrow} alt="" className="cursor-pointer" />
              <div>
                <h2 className="text-[16px]">{props.myAirlines} {props.myAirlines && "-"} {props.weatherCity}</h2>
                <p className="text-[13px] text-[#AEAEAE]">

                  {props.weatherDate && moment(props.weatherDate, 'DD.MM.YYYY').format("DD MMM")}
                  {props.dateTo && "-"}
                  {props.dateTo && moment(props.dateTo, 'DD.MM.YYYY').format("DD MMM")}
                  {props.passengers}
                  {t('pas.,')}
                  {props.ticketTarif == 'b' && t("classBusiness")}
                  {props.ticketTarif == 'e' && t("ekanomClass")}
                  {props.ticketTarif == 'f' && t("firstClass")}
                  {props.ticketTarif == 'w' && t("comfortClass")}
                  {props.ticketTarif == 'a' && t("allCalss")}
                </p>
              </div>
              <img loading="lazy" onClick={handleClickSrt} src={setting} alt="" />
            </div>
          </>
        )}

        <Modal
          open={openSrt}
          onClose={handleCloseSrt}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          closeAfterTransition
          BackdropProps={{
            timeout: 1500,
          }}
        >
          <Fade in={openSrt}>
            <Box sx={styleSrt}>

              {!showFormControl ? (
                <div className="flex-grow  overflow-auto">
                  <h1 className="text-lg font-semibold flex items-center justify-between">
                    {t('Filters')}
                  </h1>
                  <ul className="mt-[10px]">
                    {sortItems.map((item, index) => (
                      <li
                        key={index}
                        className="py-[16px] text-[16px] font-bold border-b border-[#CCCCCC] cursor-pointer"
                        onClick={() => handleClickTranStr(index)}
                      >
                        {item[titleLangKey]}
                      </li>
                    ))}
                  </ul>
                  <button onClick={() => getSortRequest()} className="bg-[#0064FA] text-[white] mt-[44px] py-[12px] w-full rounded-[8px]">
                    {t("Show variants")}
                  </button>
                </div>
              ) : (
                <>
                  {selectedIndex === 0 ? (
                    <FormControl className="animate-slide-in !w-full">
                      <h1
                        className="flex text-lg mb-[10px] font-semibold items-center justify-between w-full"
                        id="demo-radio-buttons-group-label"
                      >
                        {t("Filter")}
                      </h1>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={localStorage.getItem('rangeChecked')}
                        name="radio-buttons-group"
                        onChange={(e) => handleRangeChange(e)}
                      >
                        <FormControlLabel value="cheep" control={<Radio />} label={t("CheepFirst")} />
                        <FormControlLabel value="arr" control={<Radio />} label={t("flyDep")} />
                        <FormControlLabel value="dep" control={<Radio />} label={t("flyArr")} />
                        <FormControlLabel value="duration" control={<Radio />} label={t("duration")} />
                      </RadioGroup>
                      <button onClick={handleGotovClick} className="bg-[#0064FA] text-[#fff] rounded-[8px] py-[12px] w-full mt-[10px]">
                        {t("Ready")}
                      </button>
                    </FormControl>
                  ) : selectedIndex === 1 ? (
                    <FormControl className="animate-slide-in !px-[16px] !w-full">
                      <FormLabel id="demo-radio-buttons-group-label">{t("transfer")}</FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="all"
                        value={filterDirect == 1 ? 'noTransfer' : 'all'}
                        name="radio-buttons-group"
                        onChange={(e) => handleTransferChange(e)}
                      >
                        <FormControlLabel value="all" control={<Radio />} label={t("all")} />
                        <FormControlLabel value="noTransfer" control={<Radio />} label={t("withoutTransfer")} />

                        {/* <FormControlLabel value="transfer" control={<Radio />} label="С пересадкой" /> */}
                      </RadioGroup>
                      <button onClick={handleGotovClick} className="bg-[#0064FA] text-[#fff] rounded-[8px] py-[12px] w-full mt-[10px]">
                        {t("Ready")}
                      </button>
                    </FormControl>
                  ) : selectedIndex === 2 ? (
                    <Grid item lg={3} className=" checklistContainer ">
                      <div className="checklist">
                        <h1></h1>
                        <div className="company">
                          {allAirlinesName.length > 0 ? (
                            allAirlinesName.map((item, airIndex) => (
                              <div className="checkbox flex items-center " key={airIndex}>
                                <input
                                  type="checkbox"
                                  id={item.title}
                                  value={item.code}
                                  checked={filterAirlines.includes(item.code)}
                                  onClick={(e) => { filterInputChange(e) }}
                                />
                                <img loading="lazy" className="w-[25px] h-[25px] rounded-3xl m-[10px]" src={`https://mpics.avs.io/al_square/240/240/${item.code}.png`} alt="" />
                                <label htmlFor={item.title}>{item.title}</label>
                              </div>
                            ))
                          ) : (
                            skeletonNum?.map((item, index) => (
                              <Skeleton loading={props.isLoading}>
                                <div className="checkbox">
                                  <input type="checkbox" />
                                  <label>Uzbekistan Air</label>
                                  <label>{index === 1 && "Flydubai"}</label>
                                </div>
                              </Skeleton>
                            ))
                          )}
                        </div>
                      </div>
                      <button onClick={handleGotovClick} className="bg-[#0064FA] text-[#fff] rounded-[8px] py-[12px] w-full mt-[10px]">
                        {t("Ready")}
                      </button>
                    </Grid>
                  ) : null}
                </>
              )}
            </Box>
          </Fade>
        </Modal>

        <Grid
          container
          spacing={2}
          sx={{ justifyContent: "space-between", alignItems: "flex-start" }}>
          <Grid item lg={8} className="w-full">
            <div className="">
              {TicketData && TicketData.flights.length > 0
                ? TicketData.flights.map((item, inx) => (
                  <TicketMap key={inx} item={item} inx={inx}
                    setTicketDetail={setTicketDetail}
                    handleOpen={handleOpen}
                    currencyFnc={currencyFnc}
                    accardionOpen={accardionOpen}
                    TicketAccardion={TicketAccardion}
                    setAccardionOpen={setAccardionOpen}
                    toShoppingTicket={toShoppingTicket}
                    TicketData={TicketData}
                    allPassengrs={props.allPassengrs}
                    search={TicketData.search}
                  />
                ))
                : TicketData && (
                  <div className="flex flex-col items-center mt-[8%] md:mt-[0] mb-2">
                    <h2 className="text-[20px] font-bold md:text-[40px] mb-[12px]">
                      {t("sorry")}
                    </h2>
                    <img loading="lazy" className="w-full md:w-[50%]" src={erorr} alt="" />
                  </div>
                )}
              {/* {TicketData && TicketData.flights.length > 0 && (
                <Grid item lg={12} sx={{ marginBottom: '20px' }}>
                <div className=" w-full flex justify-center">
                <button className="bg-[#2E5B8B] text-white w-full py-3 rounded-lg" id="show_more" onClick={() => dispatch(setCountTicket(10))}>{t("showMore}</button>
                </div>
                </Grid >d
              )} */}
              {props.loading && <TicketSkeleton loading={props.loading} />}
            </div>
          </Grid>
          {TicketData?.flights?.length > 0 &&
            <Grid className={`sticky top-[200px] hidden lg:block  ${props.strongFly && 'top-[70px]'}`} lg={3}>
              <Weather weatherCity={props.weatherCity} weatherData={props.weatherDate} />
            </Grid>
          }
        </Grid>

        <div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
            BackdropProps={{
              timeout: 1500,
            }}

          >
            <Fade in={open}>
              <Box sx={style}>
                <div className="flex items-center justify-between mb-[20px] sticky top-0 bg-white z-50 h-[60px]">
                  <h1 className="text-lg font-semibold">Подробнее о маршруте</h1>
                  <img loading="lazy" onClick={() => handleClose()} src={cirClose} alt="" />
                </div>
                {
                  ticketDetail && ticketDetail.segments_count > 0 ?
                    ticketDetail.segments.map((item, index) => (
                      <div key={index}>
                        <div className="flex justify-between mb-[15px]">
                          <div>
                            <h1 className="text-lg font-semibold flex">
                              {item.carrier.title} <img loading="lazy" className="ml-[8px]" src={airplane} alt="" /></h1>
                            <p className="text-[14px] font-normal  text-[#222222]" >{item.duration.flight.hour}ч {item.duration.flight.minute} мин в пути </p>
                            {/* <p className="text-[14px] font-normal text-[#222222]">5ч 20 мин в пути, Airbus A330, рейс HH-437</p> */}
                          </div>
                          <div className="flex items-center justify-center gap-3">
                            {<img loading="lazy" className="w-10 rounded-full" src={`https://mpics.avs.io/al_square/240/240/${item.provider.supplier.code}.png`} alt="" />}
                          </div>
                        </div>
                        <div className="flex justify-between w-[90%] md:w-[60%] mb-[15px]">
                          <div>
                            <h1 className="text-lg font-semibold mb-[20px]"> {item.dep.time}
                              <p className="text-[14px]  font-normal text-[#222222] md:text-lg">
                                {moment(item.dep.date, 'DD.MM.YYYY').format("DD MMMM")},
                                {moment(item.dep.date, 'DD.MM.YYYY').format(" dddd").slice(0, 4)}
                              </p>
                            </h1>
                            <h1 className="text-lg font-semibold"> {item.arr.time}
                              <p className="text-[14px]  font-normal text-[#222222] md:text-lg">
                                {moment(item.arr.date, 'DD.MM.YYYY').format("DD MMMM")},
                                {moment(item.arr.date, 'DD.MM.YYYY').format(" dddd").slice(0, 4)}
                              </p>
                            </h1>
                          </div>
                          <img loading="lazy" src={line} alt="" />
                          <div>
                            <h1 className="text-lg font-semibold mb-[20px]"> {item.dep.city.title}
                              <p className="text-[14px]  font-normal text-[#222222]">{item.dep.city.title} ({item.dep.city.code})</p>
                              <div className="items-center  gap-[15px] text-[#2d5daf] hidden lg:flex">
                              </div>
                            </h1>
                            <h1 className="text-lg font-semibold"> {item.arr.city.title}
                              <p className="text-[14px]  font-normal text-[#222222]"> {item.arr.city.title} ({item.arr.city.code})</p>
                            </h1>
                          </div>
                        </div>
                      </div>
                    ))
                    : ""
                }
                <button className="mt-[20px] bg-[#0064FA] w-full p-[16px] text-white text-[16px] font-thin rounded-lg" onClick={() => toShoppingTicket(ticketDetail.id)}>{t("buy")}</button>
              </Box>
            </Fade>
          </Modal>
        </div>
      </div>
    </div >
  );
}

export default Ticket;