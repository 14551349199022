import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  shopTicketCom: false,
  currency: 'UZS',
  orderToken: undefined,
  open: false,
  show: false,
  langData: {},
  count: 10,
  lang: "ru",
  itemId: 1,
}

export const slice = createSlice({
  name: 'AllSlice',
  initialState,
  reducers: {
    setOpen: (state, action) => {
      state.open = action.payload
    },
    setShopTicketCom: (state, action) => {
      state.shopTicketCom = action.payload
    },
    setCurrency: (state, action) => {
      state.currency = action.payload
    },
    setOrderToken: (state, action) => {
      state.orderToken = action.payload
    },
    setLangData: (state, action) => {
      state.langData = action.payload
    },
    setLang: (state, action) => {
      state.lang = action.payload
    },
  }
})

export const { setCurrency, setLang, setLangData, setOpen, setOrderToken, setShopTicketCom } = slice.actions

export default slice.reducer 