import React, { useEffect, useRef } from 'react';

import DatePicker from 'react-multi-date-picker';
import airportAriline from '@assets/icons/airportAriline.svg';
import transition from 'react-element-popper/animations/transition';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LockPlugin, easepick } from '@easepick/bundle';


function StrongFly({
    fromCodeErr,
    setFromCodeErr,
    setToCodeErr,
    setAirlinesDataFrom,
    setMyAirlinesCode,
    airlinesDataFrom,
    toCodeErr,
    setAirlinesDataTo,
    airlinesDataTo,
    setDateCodeErr,
    searchAirportsFnc,
    formData,
    searchAirportsToFnc,
    clearSearchLine,
    inx,
    setFormData,
    date,
    handleDateChange,
    minDate,
    strongFly,
    getRecommendationSuc,
    loadingGetRecommendation,
    windowWidth,
    strongFlyArr
}) {
    const { t } = useTranslation();
    const datePickerRef = useRef(null);  // Individual reference for each date picker

    const handlePasInputChange = (selectedDate, adtInx) => {
        const formattedDate = selectedDate.format('DD-MM-YYYY');
        const formattedDateReadable = selectedDate.format('DD-MMMM');
        handleChangeVal(`date${adtInx}`, formattedDate);
        handleChangeVal(`dateFormat${adtInx}`, formattedDateReadable);
        handleDateChange(inx, selectedDate);

        setDateCodeErr(false);
    };

    const handleChangeVal = (name, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleInput = (e, name) => {
        const value = e.target.value;
        const filteredValue = value.replace(/[^a-zA-Zа-яА-ЯёЁ\s]/g, ''); // Allow Latin and Cyrillic letters and spaces
        handleChangeVal(name, filteredValue);
    };

    const handleKeyPress = (e) => {
        const charCode = e.charCode;
        const validChars =
            (charCode >= 65 && charCode <= 90) || // A-Z
            (charCode >= 97 && charCode <= 122) || // a-z
            (charCode >= 1040 && charCode <= 1103) || // А-Я, а-я
            charCode === 1025 || // Ё
            charCode === 1105 || // ё
            charCode === 32; // space

        if (!validChars) {
            e.preventDefault();
        }
    };

    const handlePaste = (e) => {
        const pasteContent = e.clipboardData.getData('text');
        if (!/^[a-zA-Zа-яА-ЯёЁ\s]*$/.test(pasteContent)) {
            e.preventDefault();
        }
    };
    useEffect(() => {
        const strongFlyEl = document.getElementById('strongFly');
        const gradientBG = document.getElementById('gradient-canvas');
        const mainContainer = document.getElementById('mainContainer');
        if (strongFly && !getRecommendationSuc && strongFly && !loadingGetRecommendation) {
            // Set height based on strongFly element height
            // gradientBG.style.height = `calc(80vh + ${strongFlyEl.offsetHeight}px)`;
            //   mainContainer.style.height = `calc(80vh + ${strongFlyEl.offsetHeight}px)`;
        }
       
        else {
            // mainContainer.style.height = windowWidth < 1024 ? "460px" : (getRecommendationSuc || loadingGetRecommendation ? "auto" : "80vh");
            // mainContainer.style.minHeight = windowWidth < 1024 ? "460px" : (getRecommendationSuc || loadingGetRecommendation ? "auto" : "80vh");
            // gradientBG.style.height = windowWidth < 1024 ? "460px" : "80vh";
            // gradientBG.style.minHeight = windowWidth < 1024 ? "460px" : "80vh";
        }

    }, [strongFly, strongFlyArr]);

    useEffect(() => {
        datePickerRef.current = new easepick.create({
            element: document.getElementById(`datepicker-${inx}`), // Unique id for each instance
            css: [
                'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css',
            ],
            calendars: 1,
            zIndex: 51,
            lang: 'ru-Ru',
            grid: 1,
            format: "DD.MM.YYYY",
            plugins: [LockPlugin],
            LockPlugin: {
                minDate: minDate || new Date(), // Use minDate passed as prop
            },
            setup(picker) {
                picker.on('select', (e) => {
                    // Access the selected date from the 'detail' object
                    const selectedDate = e.detail.date;
                    handlePasInputChange(selectedDate, inx);
                });
            }
        });
    }, [inx, minDate]); // Rerun whenever the inx or minDate changes

    return (
        <div className="h-[66px] hidden lg:flex items-center rounded-lg relative mt-[3px]" key={inx} id={`searchLine${inx}`}>
            <div className="h-full w-full flex items-center rounded-lg">
                {/* From Airport Input */}
                <div className="relative h-full w-full" style={{ padding: '0' }}>
                    <div className={`h-full w-full rounded-l-lg border border-[#c0bfbf] px-[10px] flex justify-center flex-col  ${loadingGetRecommendation || getRecommendationSuc ? 'bg-[#F7F7F7]' : 'bg-white'}`}>
                        <input
                            onKeyPress={handleKeyPress}
                            onPaste={handlePaste}
                            className={`${fromCodeErr !== false && fromCodeErr === inx - 1 && 'placeholder-red-700'}  w-full capitalize outline-none ${loadingGetRecommendation || getRecommendationSuc ? 'bg-[#F7F7F7]' : 'bg-white'}`}
                            type="text"
                            placeholder={t("from")}
                            onChange={(e) => {
                                handleInput(e, `from${inx}`);
                                handleChangeVal(`fromActive${inx}`, true);
                                searchAirportsFnc(e.target.value, inx);

                                setFromCodeErr(false);
                            }}
                            value={formData[`from${inx}`]}
                        />
                        {formData[`from${inx}`] && (
                            <p className="flex items-center text-[13px] text-[#AEAEAE]">
                                {formData[`fromCode${inx}`]}
                                <div className="h-[2px] w-[2px] bg-[#AEAEAE] rounded-full mx-[5px]"></div>
                                {formData[`fromCity${inx}`]}
                            </p>
                        )}
                        {fromCodeErr !== false && fromCodeErr === inx - 1 && <p className="text-red-700 text-[0.8rem] mt-[8px]">{t("requiredFild")}</p>}
                    </div>

                    {airlinesDataFrom && formData[`fromActive${inx}`] && (
                        <div className="searchDataList z-10">
                            {airlinesDataFrom.map((item, index) => (
                                <div key={index} className="border-b border-gray-300  flex flex-col">
                                    <div className="hover:bg-[#3379CB] p-[16px] w-full hover:text-[#fff]"
                                        onClick={() => {
                                            handleChangeVal(`from${inx}`, item.cityName);
                                            handleChangeVal(`fromCode${inx}`, item.cityIataCode);
                                            handleChangeVal(`fromCity${inx}`, item.cityName);
                                            handleChangeVal(`fromActive${inx}`, false);
                                            // setMyAirlinesCode(item.cityIataCode);
                                            setAirlinesDataFrom();
                                        }}
                                    >
                                        <p className="font-semibold">
                                            {item.cityName}, <span className="text-[#AEAEAE] font-normal">{item.countryName}</span>
                                        </p>
                                        <p className="text-[#AEAEAE] font-medium">{item.cityIataCode}</p>
                                    </div>
                                    <div className="flex flex-col">
                                        {
                                            item.airports && item.airports.length > 0 && item.airports.map((airport, airInx) => (
                                                <div
                                                    key={airInx}
                                                    onClick={() => {
                                                        handleChangeVal(`from${inx}`, airport.airportName);
                                                        handleChangeVal(`fromCode${inx}`, airport.airportIataCode);
                                                        handleChangeVal(`fromCity${inx}`, airport.airportName);
                                                        handleChangeVal(`fromActive${inx}`, false);
                                                        setAirlinesDataFrom();
                                                    }}
                                                    className="pr-[16px] pl-[40px] hover:bg-[#3379CB] hover:text-[#fff] py-[13px] flex items-center justify-between "
                                                >
                                                    <p className="flex items-center gap-[4px]">
                                                        <img loading="lazy" src={airportAriline} alt="" />
                                                        {airport.airportName}
                                                    </p>
                                                    <p className="text-[#AEAEAE] font-medium">{airport.airportIataCode}</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                {/* DatePicker for 'From' */}


                {/* To Airport Input */}
                <div className="relative h-full w-full" style={{ padding: '0' }}>
                    <div className={`h-full w-full border border-[#c0bfbf] px-[10px] flex justify-center flex-col ${loadingGetRecommendation || getRecommendationSuc ? 'bg-[#F7F7F7]' : 'bg-white'}`}>
                        <input
                            onKeyPress={handleKeyPress}
                            onPaste={handlePaste}
                            className={`${toCodeErr !== false && toCodeErr === inx - 1 && 'placeholder-red-700'} ticketToInput w-full outline-none ${loadingGetRecommendation || getRecommendationSuc ? 'bg-[#F7F7F7]' : 'bg-white'}`}
                            type="text"
                            placeholder={t("where")}
                            onChange={(e) => {
                                handleInput(e, `to${inx}`);
                                handleChangeVal(`toActive${inx}`, true);
                                searchAirportsToFnc(e.target.value, inx);
                                setToCodeErr(false);
                            }}
                            value={formData[`to${inx}`]}
                        />
                        {formData[`to${inx}`] && (
                            <p className="flex items-center text-[13px] text-[#AEAEAE]">
                                {formData[`toCode${inx}`]}
                                <div className="h-[2px] w-[2px] bg-[#AEAEAE] rounded-full mx-[5px]"></div>
                                {formData[`toCity${inx}`]}
                            </p>
                        )}
                        {toCodeErr !== false && toCodeErr === inx - 1 && <p className="text-red-700 text-[0.8rem] mt-[8px]">{t("requiredFild")}</p>}
                    </div>
                    {airlinesDataTo && formData[`toActive${inx}`] && (
                        <div className="searchDataList2 z-10">
                            {airlinesDataTo.map((item, index) => (
                                <div key={index} className="border-b border-gray-300  flex flex-col">
                                    <div className="hover:bg-[#3379CB] p-[16px] w-full hover:text-[#fff]"
                                        onClick={() => {
                                            handleChangeVal(`to${inx}`, item.cityName);
                                            handleChangeVal(`toCode${inx}`, item.cityIataCode);
                                            handleChangeVal(`toCity${inx}`, item.cityName);
                                            handleChangeVal(`toActive${inx}`, false);
                                            // setMyAirlinesCode(item.cityIataCode);
                                            setAirlinesDataTo();
                                        }}
                                    >
                                        <p className="font-semibold">
                                            {item.cityName}, <span className="text-[#AEAEAE] font-normal">{item.countryName}</span>
                                        </p>
                                        <p className="text-[#AEAEAE] font-medium">{item.cityIataCode}</p>
                                    </div>
                                    <div className="flex flex-col">
                                        {
                                            item.airports && item.airports.length > 0 && item.airports.map((airport, airInx) => (
                                                <div
                                                    key={airInx}
                                                    onClick={() => {
                                                        handleChangeVal(`to${inx}`, airport.airportName);
                                                        handleChangeVal(`toCode${inx}`, airport.airportIataCode);
                                                        handleChangeVal(`toCity${inx}`, airport.airportName);
                                                        handleChangeVal(`toActive${inx}`, false);
                                                        // setMyAirlinesCode(airport.airportIataCode);
                                                        setAirlinesDataTo();
                                                    }}
                                                    className="pr-[16px] pl-[40px] hover:bg-[#3379CB] hover:text-[#fff] py-[13px] flex items-center justify-between "
                                                >
                                                    <p className="flex items-center gap-[4px]">
                                                        <img loading="lazy" src={airportAriline} alt="" />
                                                        {airport.airportName}
                                                    </p>
                                                    <p className="text-[#AEAEAE] font-medium">{airport.airportIataCode}</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className={`h-full w-full flex flex-col rounded-r-lg border px-[10px] pb-1  justify-center border-[#c0bfbf] !outline-none ${loadingGetRecommendation || getRecommendationSuc ? 'bg-[#F7F7F7]' : 'bg-white'}`}>
                    <input
                        readOnly
                        value={formData[`date${inx}`]}
                        id={`datepicker-${inx}`} // Unique id for each DatePicker input
                        placeholder={t("when")}
                        className={` ${loadingGetRecommendation || getRecommendationSuc ? 'bg-[#F7F7F7]' : 'bg-white'} cursor-pointer w-full outline-none h-[60px] px-5`}
                        onClick={() => datePickerRef.current.show()} // Corrected to show() instead of open()
                    />
                </div>

                {/* Button to clear search */}
                {inx !== 1 && (
                    <div className="absolute right-[-15px] top-[25%] bg-[#fff] rounded-full cursor-pointer" onClick={() => clearSearchLine(inx)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="#fff" className="rounded-full">
                            <path
                                d="M16.0001 2.66675C8.65341 2.66675 2.66675 8.65341 2.66675 16.0001C2.66675 23.3467 8.65341 29.3334 16.0001 29.3334C23.3467 29.3334 29.3334 23.3467 29.3334 16.0001C29.3334 8.65341 23.3467 2.66675 16.0001 2.66675ZM20.4801 19.0667C20.8667 19.4534 20.8667 20.0934 20.4801 20.4801C20.2801 20.6801 20.0267 20.7734 19.7734 20.7734C19.5201 20.7734 19.2667 20.6801 19.0667 20.4801L16.0001 17.4134L12.9334 20.4801C12.7334 20.6801 12.4801 20.7734 12.2267 20.7734C11.9734 20.7734 11.7201 20.6801 11.5201 20.4801C11.1334 20.0934 11.1334 19.4534 11.5201 19.0667L14.5867 16.0001L11.5201 12.9334C11.1334 12.5467 11.1334 11.9067 11.5201 11.5201C11.9067 11.1334 12.5467 11.1334 12.9334 11.5201L16.0001 14.5867L19.0667 11.5201C19.4534 11.1334 20.0934 11.1334 20.4801 11.5201C20.8667 11.9067 20.8667 12.5467 20.4801 12.9334L17.4134 16.0001L20.4801 19.0667Z"
                                fill="#c9357a"
                            />
                        </svg>
                    </div>
                )}
            </div>
        </div>
    );
}

export default StrongFly;
