import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const loggedIn = localStorage.getItem("access")
export const BookingApi = createApi({
    reducerPath: "BookingApi",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://api.mysafar.ru",
    }),

    endpoints: (builder) => ({

        bookingCreate: builder.mutation({
            query: (bookBody) => ({
                url: "/avia/booking-create",
                method: "POST",
                body: bookBody,
                headers: {
                    Authorization: loggedIn && `Bearer ${localStorage.getItem("access")}`
                }
            }),
        }),

        bookingConfirm: builder.mutation({
            query: (bookBody) => ({
                url: "/avia/booking-confirm",
                method: "POST",
                body: bookBody,
                headers: {
                    Authorization: loggedIn && `Bearer ${localStorage.getItem("access")}`
                }
            }),
        }),

        paymentConfirm: builder.mutation({
            query: (paymentBody) => ({
                url: "/avia/payment-confirm",
                method: "POST",
                body: paymentBody,
                headers: {
                    Authorization: loggedIn && `Bearer ${localStorage.getItem("access")}`
                }
            }),
        }),
        getReceivedTicketData: builder.mutation({
            query: (id) => ({
                url: `/avia/ticket-data/${id}`,
                method: "POST",
                headers: {
                    Authorization: loggedIn && `Bearer ${localStorage.getItem("access")}`
                }
            }),
        }),
    }),
});

export const { useBookingCreateMutation, useBookingConfirmMutation, usePaymentConfirmMutation, useGetReceivedTicketDataMutation } = BookingApi;
