import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const RegisterApi = createApi({
    reducerPath: "RegisterApi",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://api.mysafar.ru",
    }),

    endpoints: (builder) => ({
        registerApi: builder.mutation({
            query: (registerBody) => ({
                url: "/auth/google-auth",
                method: "POST",
                body: registerBody,
            }),
        }),

        registerFacebook: builder.mutation({
            query: (registerBody) => ({
                url: "/auth/facebook/",
                method: "POST",
                body: registerBody,
            }),
        }),

        registerOne: builder.mutation({
            query: (registerOneBody) => ({
                url: "/auth/phone-register-one",
                method: "POST",
                body: registerOneBody,
            }),
        }),

        registerTwo: builder.mutation({
            query: (registerTwoBody) => ({
                url: "/auth/phone-register-two",
                method: "POST",
                body: registerTwoBody,
            }),
        }),

        registerPhone: builder.mutation({
            query: (registerPhoneBody) => ({
                url: "/auth/phone-register",
                method: "POST",
                body: registerPhoneBody,
            }),
        }),
    }),
});

export const { useRegisterApiMutation, useRegisterFacebookMutation, useRegisterOneMutation, useRegisterPhoneMutation, useRegisterTwoMutation } = RegisterApi;
