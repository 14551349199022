import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const ProfileApi = createApi({
    reducerPath: "ProfileApi",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://api.mysafar.ru",
    }),

    endpoints: (builder) => ({
        updateProfile: builder.mutation({
            query: (updProfileBody) => ({
                url: "/auth/update-profile",
                method: "PATCH",
                body: updProfileBody,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access")}`
                }
            }),
        }),

        userProfile: builder.mutation({
            query: () => ({
                url: "/profile",
                method: "GET",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access")}`
                }
            }),
        }),

        userDetail: builder.mutation({
            query: (id) => ({
                url: `/admina/user-detail/${id}`,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access")}`
                }
            }),
        }),

        userConfirmedTickets: builder.mutation({
            query: () => ({
                url: "avia/user-confirmed-tickets",
                method: "GET",
                headers: {
                    Authorization: localStorage.getItem("access") && `Bearer ${localStorage.getItem("access")}`
                }
            }),
        }),

        getCheque: builder.mutation({
            query: () => ({
                url: "main/cheques",
                method: "GET",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access")}`
                }
            }),
        }),
        searchHistoryPost: builder.mutation({
            query: (historyDate) => ({
                url: "/main/search-history_post",
                method: "POST",
                body: historyDate,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access")}`
                }
            }),
        }),
    }),
});

export const { useUpdateProfileMutation, useUserProfileMutation, useUserDetailMutation, useUserConfirmedTicketsMutation, useGetChequeMutation, useSearchHistoryPostMutation } = ProfileApi;
