import React from 'react';
import { Fade, Modal } from "@mui/material";
import Box from '@mui/material/Box';
import leftArrBlue from "@assets/icons/arrow-leftBlue.svg";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'; // Redux hooks
import { usePopularCitesMutation } from '@services/AllApi';
import { useEffect } from 'react';
import shareWhite from "@assets/icons/shareWhite.svg"

// Styles for the modal Box
const style = {
    position: 'absolute',
    bottom: '0',
    left: '0',
    width: '100%',
    bgcolor: 'background.paper',
    boxShadow: '0px -24px 24px rgba(0, 0, 0, 0.2)',
    padding: '20px',
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    height: "90vh",
    overflowY: "scroll",
    transition: '1s'
};

// The FromModal component
function FromModal({
    myAirlinesInpErr,
    myAirlines,
    setMyAirlines,
    searchAirportsFnc,
    airlinesDataFrom,
    setMyAirlinesCode,
    setAirlinesDataFrom,
    setMyAirlinesCountry,
    airportAriline,
    open, setOpen,
    handleClose, setOpen2,
    setOpen3
}) {
    const lang = useSelector((state) => state.AllSlice.lang)
    const currency = useSelector((state) => state.AllSlice.currency);
    const [popularCites, { data: popularCitesData, isSuccess: popularCitesSuc }] = usePopularCitesMutation()
    const { t } = useTranslation();
    const handleClose2 = () => setOpen2(false);
    const handleOpen3 = () => setOpen3(true);


    const formatCurrency = (number, currency) => {
        return new Intl.NumberFormat({ style: 'currency', currency }).format(number);
    };

    useEffect(() => {
        try {
            popularCites({})
        } catch (error) { console.log(error); }
    }, [])

    const toLangKey = `to_${lang}`;

    const priceLangKey = `price_${currency.toLowerCase()}`;


    // Handle modal close


    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
            BackdropProps={{
                timeout: 1500,
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <div className="flex items-center">
                        <img
                            loading="lazy"
                            onClick={handleClose}
                            src={leftArrBlue}
                            alt="Close"
                        />
                        <input
                            className={` ${myAirlinesInpErr && 'placeholder-red-700'} h-full w-full capitalize outline-none px-[10px]`}
                            type="text"
                            autoFocus
                            placeholder={t("from")}
                            value={myAirlines || ''}
                            onChange={(e) => {
                                setMyAirlines(e.target.value);
                                searchAirportsFnc(e.target.value);
                            }}
                        />
                    </div>
                    <button onClick={() => { handleOpen3(); handleClose2() }} className="bg-[#27AE60] mb-[32px] text-5 w-full rounded-lg text-[#fff] py-[5px] flex items-center justify-center mt-4"> <img loading="lazy" className="mr-[12px]" src={shareWhite} alt="" />{t("dificultFly")}</button>
                    <div className="p-[16px]">
                        {
                            airlinesDataFrom ?
                                airlinesDataFrom?.map((item, index) => (
                                    <div className="py-[16px] border-b border-[#CCCCCC]" key={index}>
                                        <div
                                            className="flex items-center justify-between"
                                            onClick={() => {
                                                setMyAirlines(item.cityName);
                                                setMyAirlinesCode(item.cityIataCode);
                                                setAirlinesDataFrom();
                                                handleClose();
                                            }}
                                        >
                                            <p className="text-lg">
                                                <span className="font-semibold text-[#222222]">{item.cityName}</span>, {item.countryName}
                                            </p>
                                            <p className="text-[#AEAEAE] font-semibold text-lg">{item.cityIataCode}</p>
                                        </div>
                                        <div className="flex flex-col">
                                            {item.airports && item.airports.length > 0 && item.airports.map((airport, airInx) => (
                                                <div
                                                    key={airInx}
                                                    onClick={() => {
                                                        setMyAirlines(airport.airportName);
                                                        setMyAirlinesCode(airport.airportIataCode);
                                                        setMyAirlinesCountry(item.cityIataCode);
                                                        setAirlinesDataFrom([]);
                                                        handleClose();
                                                    }}
                                                    className="px-[16px] hover:bg-[#3379CB] hover:text-[#fff] py-[13px] flex items-center justify-between"
                                                >
                                                    <p className="flex items-center gap-[4px]">
                                                        <img loading="lazy" src={airportAriline} alt="Airport Icon" />
                                                        {airport.airportName}
                                                    </p>
                                                    <p className="text-[#AEAEAE] font-medium">{airport.airportIataCode}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))
                                :
                                popularCitesSuc && popularCitesData.map((item, inx) => (
                                    <div onClick={() => (setMyAirlines(item[toLangKey]), setMyAirlinesCode(item.to_iata), setOpen(false))} className='flex items-center  mb-[30px] gap-[16px] rounded-lg' key={inx}>
                                        <div className=''>
                                            <img loading="lazy"
                                                className={`rounded-lg w-[80px] h-[80px]`}
                                                // src={`https://gw.flysells.uz${item.img}`}
                                                src={`${item.img}`}
                                                alt="..."
                                            />
                                        </div>
                                        <div>
                                            <h4 className='text-5 font-bold lg:mb-[5px] text-[#2E5B8B]' >{item[toLangKey]}</h4>
                                            <p className='text-[#AEAEAE] text-[16px] flex items-center gap-[2px]'>
                                                {t("to")} {item[priceLangKey] && formatCurrency(item[priceLangKey], currency)}
                                                < p > {currency == 'RUB' && '₽'}</p>
                                                <p>{currency == 'UZS' && t("sum")}</p>
                                                < p > {currency == 'USD' && '$'}</p>
                                            </p></div>
                                    </div>
                                ))}
                    </div>
                </Box>
            </Fade>
        </Modal >
    );
}

export default FromModal;
