import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
    ticketData: undefined,
    getRecommendationSuc: undefined,
    loadingGetRecommendation: undefined,
    getRecommendationData: undefined,
    getRecommendationErr: undefined,
    ticketAdults: 1,
    ticketChild: 0,
    ticketBabies: 0,
    ticketTarif: 'a',
    ticketId: '',
    filterAirlines: [],
    changeFilterAir: false,
    allAirlinesName: [],
    ticketDetail: [],
    filterDirect: 0,
    filterDirectChange: undefined,
    popularCitesName: '',
    popularCitesCode: '',
    popularCitesCountry: '',
    countTicket: 20,
    priceOrder: '',
    arrOrder: 0,
    depOrder: 0,
    durationOrder: 0,
    changeFilterDirect: false,
    myAirlinesToInpErr: false,
    getRecLoad: false,
    getRecSuc: false,
    popularCitesNameFrom: "",
    popularCitesCodeFrom: "",
    popularCitesCountryFrom: "",
    strongFly: false,
    strongFlyNum: 1,
    strongFlyArr: [1],
    datePickerBack: true,
    activeIndex: 0,
    getSortRequest: false
}



export const slice = createSlice({
    name: 'HomeSlice',
    initialState,
    reducers: {
        changeTicketData: (state, action) => {
            state.ticketData = action.payload
        },
        setTicketAdult: (state, action) => {
            state.ticketAdults = action.payload
        },
        setTicketChild: (state, action) => {
            state.ticketChild = action.payload
        },
        setTicketDetail: (state, action) => {
            state.ticketDetail = action.payload
        },
        setTicketBabies: (state, action) => {
            state.ticketBabies = action.payload
        },
        setTicketTarif: (state, action) => {
            state.ticketTarif = action.payload
        },
        setTicketId: (state, action) => {
            state.ticketId = action.payload
        },
        addFilterAirlines: (state, action) => {
            state.filterAirlines = [...state.filterAirlines.filter(item => (item !== action.payload)), action.payload]
        },
        removeFilterAirlines: (state, action) => {
            state.filterAirlines = [...state.filterAirlines.filter(item => (item !== action.payload))]
        },
        clearFilterAirlines: (state, action) => {
            state.filterAirlines = []
        },
        checkChangeAir: (state, action) => {
            state.changeFilterAir = true
        },
        addFilterAirlinesName: (state, action) => {
            state.allAirlinesName = action.payload
        },
        clearFilterAirlinesName: (state, action) => {
            state.allAirlinesName = []
        },
        setFilterDirect: (state, action) => {
            state.filterDirect = action.payload
        },
        setFilterDirectChange: (state, action) => {
            state.filterDirectChange = action.payload
        },
        setPopularCities: (state, action) => {
            state.popularCitesName = action.payload
        },
        setPopularCitesCode: (state, action) => {
            state.popularCitesCode = action.payload
        },
        setPopularCitesCountry: (state, action) => {
            state.popularCitesCountry = action.payload
        },
        setCountTicket: (state, action) => {
            state.countTicket = state.countTicket + action.payload
        },
        setPriceOrder: (state, action) => {
            state.priceOrder = action.payload
        },
        setArrOrder: (state, action) => {
            state.arrOrder = action.payload
        },
        setDepOrder: (state, action) => {
            state.depOrder = action.payload
        },
        setDurationOrder: (state, action) => {
            state.durationOrder = action.payload
        },
        setChangeFilterDirect: (state, action) => {
            state.changeFilterDirect = action.payload
        },
        setMyAirlinesToInpErr: (state, action) => {
            state.myAirlinesToInpErr = action.payload
        },
        setGetRecSuc: (state, action) => {
            state.getRecSuc = action.payload
        },
        setGetRecLoad: (state, action) => {
            state.getRecLoad = action.payload
        },
        setGetRecommendationSuc: (state, action) => {
            state.getRecommendationSuc = action.payload
        },
        setLoadingGetRecommendation: (state, action) => {
            state.loadingGetRecommendation = action.payload
        },
        setGetRecommendationData: (state, action) => {
            state.getRecommendationData = action.payload
        },
        setGetRecommendationErr: (state, action) => {
            state.getRecommendationErr = action.payload
        },
        setStrongFly: (state, action) => {
            state.strongFly = action.payload
        },
        setStrongFlyNum: (state, action) => {
            state.strongFlyNum = action.payload
        },
        setStrongFlyArr: (state, action) => {
            state.strongFlyArr = action.payload
        },
        setDatePickerBack: (state, action) => {
            state.datePickerBack = action.payload
        },
        setActiveIndex: (state, action) => {
            state.activeIndex = action.payload
        },
        changeGetSortRequest: (state, action) => {
            state.getSortRequest = action.payload
        }
    }
})

export const { changeTicketData, setArrOrder,
    setChangeFilterDirect, setCountTicket,
    setDepOrder, setDurationOrder, setFilterDirect,
    setFilterDirectChange, setGetRecLoad, setGetRecSuc,
    setMyAirlinesToInpErr, setPopularCitesCode, setPopularCitesCountry,
    setPopularCities, setPriceOrder, setTicketAdult, setTicketBabies,
    setTicketChild, setTicketDetail, setTicketId, setTicketTarif,
    removeFilterAirlines, addFilterAirlines, addFilterAirlinesName,
    checkChangeAir, clearFilterAirlines, clearFilterAirlinesName,
    setGetRecommendationSuc, setLoadingGetRecommendation,
    setGetRecommendationData, setGetRecommendationErr,
    setStrongFly, setStrongFlyArr, setStrongFlyNum, setDatePickerBack,
    setActiveIndex, changeGetSortRequest
} = slice.actions

export default slice.reducer