import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const getMyCityApi = createApi({
  reducerPath: "getMyCityPath",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://suggest.aviasales.com/v2/nearest_places.json",
  }),

  endpoints: (builder) => ({
    getMyCity: builder.query({
      query: () => ({
        url: "?locale=ru_UZ",
        method: 'GET',
        headers: {
          'accept-language': 'ru',  // Specify the desired language here
        },
      }),
    }),
  }),
});

export const { useGetMyCityQuery } = getMyCityApi;
