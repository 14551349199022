import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const LoginApi = createApi({
  reducerPath: "LoginApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.mysafar.ru",
  }),

  endpoints: (builder) => ({
    refresh: builder.mutation({
      query: (refreshBody) => ({
        url: "/api/v1/token/refresh/",
        method: "POST",
        body: refreshBody,
      }),
    }),


    loginPhone: builder.mutation({
      query: (loginPhoneBody) => ({
        url: "/auth/phone-login",
        method: "POST",
        body: loginPhoneBody,
      }),
    }),

    resetPassword: builder.mutation({
      query: ({resetPasswordBody, Bearer}) => ({
        url: "/auth/set-password",
        method: "PATCH",
        body: resetPasswordBody,
        headers: {
          Authorization: `Bearer ${Bearer}`
        }
      }),
    }),

  }),
});

export const { useRefreshMutation, useLoginPhoneMutation, useResetPasswordMutation } = LoginApi;
