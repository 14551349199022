import React, { useRef, useState } from 'react'
import arrowDown from "@assets/icons/arrow-down.svg"
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import RadioGroup from '@mui/material/RadioGroup';
import { FormControl, FormControlLabel, Radio } from '@mui/material';
import { styled } from '@mui/material/styles';


const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&::before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#106ba3',
    },
});


function PassengerDropdown({ setClassModalShow, classModalShow, totalPassengers, getPassengerText, ticketChild, ticketTarif, ticketAdults, ticketBabies, setTicketAdult, setTicketBabies, setTicketChild, strongFly, getRecommendationSuc, loadingGetRecommendation, setIsBaggage, isBaggage, setticketTarif }) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const dropdownRef = useRef()
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    const handleDropdownClick = () => {
        setClassModalShow(!classModalShow);
    };

    function BpRadio(props) {
        return (
            <Radio
                disableRipple
                color="default"
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
                {...props}
            />
        );
    }


    return (
        <div
            onMouseLeave={() => setClassModalShow(false)}
            className={`flex items-center h-[66px] mt-3 lg:mt-0  w-full border border-[#c0bfbf] outline-none px-2 sm:px-4 relative
                ${!strongFly && windowWidth < 1024 ? 'lg:rounded-lg rounded-lg' : 'lg:rounded-r-lg'} ${loadingGetRecommendation || getRecommendationSuc ? 'bg-[#f7f7f7]' : 'bg-white'}`}  // Apply rounded-r-lg when strongFly is false and screen width is >1024px (lg)
            ref={dropdownRef}
        >
            <div className="flex items-center justify-between w-full cursor-pointer" onClick={handleDropdownClick}>
                <div>
                    <h3 className="font-medium text-sm sm:text-base">{totalPassengers} {getPassengerText(totalPassengers)}</h3>
                    <p className='text-[#AEAEAE] font-normal text-sm'>
                        {ticketTarif == 'b' && t("classBusiness")}
                        {ticketTarif == 'e' && t("ekanomClass")}
                        {ticketTarif == 'f' && t("firstClas")}
                        {ticketTarif == 'w' && t("comfortClass")}
                        {ticketTarif == 'a' && t("allCalss")}
                    </p>
                </div>
                <img loading="lazy" className={`${classModalShow ? 'rotate-[180deg] duration-[0.3s]' : "rotate-0 duration-[0.3s]"}`} src={arrowDown} alt="" />
            </div>

            {classModalShow && (
                <div className="absolute left-0 p-5 mt-[0.5px]  bg-white z-10 text-[#222] shadow-lg rounded-lg w-full lg:w-[350px] max-w-md sm:max-w-lg lg:max-w-2xl" style={{ top: 'calc(100% + 0px)' }}>
                    <h3 className="text-lg font-medium">{ticketAdults + ticketChild + ticketBabies} {getPassengerText(totalPassengers)}</h3>

                    <div className="flex items-center justify-between my-3">
                        <p className="text-lg mr-5 text-[#222] font-light">{t("oldTwenty")}</p>
                        <div className="flex items-center justify-between">
                            <button
                                className={`${ticketAdults > 1 ? 'bg-[#3379CB]' : 'bg-[#AEAEAE]'}  text-xl w-12 h-12 flex items-center justify-center rounded-lg text-white`}
                                onClick={() => ticketAdults > 1 && (setTicketAdult(ticketAdults - 1))}
                            >
                                -
                            </button>
                            <h1 className="mx-3 text-sm sm:text-base">{ticketAdults}</h1>
                            <button
                                className="bg-[#3379CB] w-12 h-12  text-xl  flex items-center justify-center rounded-lg text-white"
                                onClick={() => (setTicketAdult(ticketAdults + 1))}
                            >
                                +
                            </button>
                        </div>
                    </div>

                    <div className="flex items-center justify-between mb-3">
                        <h2 className="text-lg mr-5 text-[#222] font-light">{t("from1To12")}</h2>
                        <div className="flex items-center justify-between">
                            <button
                                className={`${ticketChild > 0 ? 'bg-[#3379CB]' : 'bg-[#AEAEAE]'}  w-12 h-12 flex items-center justify-center text-xl rounded-lg text-white`}
                                onClick={() => ticketChild > 0 && (setTicketChild(ticketChild - 1))}
                            >
                                -
                            </button>
                            <h1 className="mx-3 text-sm sm:text-base">{ticketChild}</h1>
                            <button
                                className="bg-[#3379CB] w-12 h-12  text-xl  rounded-lg text-white"
                                onClick={() => (setTicketChild(ticketChild + 1))}
                            >
                                +
                            </button>
                        </div>
                    </div>

                    <div className="flex items-center justify-between mb-3">
                        <h2 className="text-lg mr-5 text-[#222] font-light">{t("upTo2")}</h2>
                        <div className="flex items-center justify-between">
                            <button
                                className={`${ticketBabies > 0 ? 'bg-[#3379CB]' : 'bg-[#AEAEAE]'}  text-xl w-12  flex items-center justify-center h-12 rounded-lg text-white`}
                                onClick={() => ticketBabies > 0 && (setTicketBabies(ticketBabies - 1))}
                            >
                                -
                            </button>
                            <h1 className="mx-3 text-sm sm:text-base">{ticketBabies}</h1>
                            <button
                                className={`${ticketAdults > ticketBabies ? 'bg-[#3379CB]' : "bg-[#AEAEAE]"}  text-xl flex items-center justify-center  w-12 h-12 rounded-lg text-white`}
                                onClick={() => ticketAdults > ticketBabies && (setTicketBabies(ticketBabies + 1))}
                            >
                                +
                            </button>
                        </div>
                    </div>

                    <div className="border-b pb-4 pt-2">
                        <p className="text-sm sm:text-base">{t("enterAge")}</p>
                    </div>

                    <div className="border-b py-2">
                        <FormControl className="w-full">
                            <RadioGroup
                                defaultValue="female"
                                aria-labelledby="demo-customized-radios"
                                name="customized-radios"
                                value={ticketTarif}
                                onChange={(e) => (setticketTarif(e.target.value))}
                            >
                                <div className="block">
                                    <FormControlLabel className="w-full justify-between m-0 p-0" value="e" control={<BpRadio />} labelPlacement="start" label={t("ekanomClass")} />
                                    <FormControlLabel className="w-full justify-between m-0 p-0 my-2" value="b" control={<BpRadio />} labelPlacement="start" label={t("classBusiness")} />
                                    <FormControlLabel className="w-full justify-between m-0 p-0" value="f" control={<BpRadio />} labelPlacement="start" label={t("firstClass")} />
                                    <FormControlLabel className="w-full justify-between m-0 p-0" value="w" control={<BpRadio />} labelPlacement="start" label={t("comfortClass")} />
                                    <FormControlLabel className="w-full justify-between m-0 p-0" value="a" control={<BpRadio />} labelPlacement="start" label={t("allCalss")} />
                                </div>
                            </RadioGroup>
                        </FormControl>
                    </div>


                </div>
            )}
        </div>
    )

}

export default PassengerDropdown;
